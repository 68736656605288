import styled from '@emotion/styled';
import { useEffect, useState } from 'react';
import { Button } from 'ui/components/company/elements/Button/Button';
import { ListTable } from 'ui/components/company/features/ListTable/ListTable';
import { CompanyMfDepartmentSelect } from 'ui/components/company/patterns/CompanyMfDepartment/CompanyMfDepartmentSelect';
import { useModal } from 'ui/components/company/screens/modals/useModal';
import { usePaymentBillQuery } from './usePaymentBillQuery';
import { usePaymentQuery } from './usePaymentQuery';

const Wrapper = styled.div`
  padding: 40px;
  min-width: 550px;
`;

const PageTitle = styled.div`
  font-size: 22px;
  font-weight: 700;
  padding-bottom: 20px;
`;

const Buttons = styled.div`
  display: flex;
  flex-direction: column;
  align-items: end;
  gap: 8px;
  padding-right: 12px;
  width: 100%;
`;

export const CompanyBillingList = () => {
  const { data } = usePaymentQuery();
  const {
    fetchPaymentBill,
    fetching,
    refetchDepartments,
    moneyForwardDepartments,
    departmentsRefetching,
  } = usePaymentBillQuery();
  const [selectedDepartmentId, setSelectedDepartmentId] = useState<string | undefined>();
  const [selectedPaymentId, setSelectedPaymentId] = useState<number | undefined>();
  const requestDocumentModal = useModal();

  const handleGenDocument = ({ id }: { id: number }) => {
    setSelectedPaymentId(id);
    requestDocumentModal.openModal();
  };

  const handleRequestDocument = () => {
    if (selectedDepartmentId && selectedPaymentId) {
      fetchPaymentBill({ id: selectedPaymentId, departmentId: selectedDepartmentId });
    }
  };

  useEffect(() => {
    if (selectedDepartmentId === undefined && moneyForwardDepartments) {
      setSelectedDepartmentId(moneyForwardDepartments[0].id);
    }
  }, [moneyForwardDepartments]);

  if (!data) return <div>Loading...</div>;

  return (
    <Wrapper>
      <PageTitle>請求履歴</PageTitle>

      <div
        style={{
          maxWidth: '800px',
          margin: '0 auto',
          textAlign: 'center',
        }}
      ></div>

      <div>
        <ListTable<(typeof data)['payments'][number]>
          columns={[
            {
              key: 'method',
              label: '支払い方法',
              cellRenderer: (row) => (row.method === 'CREDIT_CARD' ? 'クレジットカード' : '請求書'),
            },
            {
              key: 'line_items',
              label: '明細',
              cellRenderer: (row) => (
                <div>
                  {row.line_items.map((li) => (
                    <div key={li.id}>
                      {li.product_name} {li.unit_price}円 x {li.amount}
                    </div>
                  ))}
                </div>
              ),
            },
            {
              key: 'created_at',
              label: '決済日時',
              cellRenderer: (row) => new Date(row.created_at).toLocaleString(),
            },
            {
              key: '',
              label: '',
              cellRenderer: (row) => (
                <Buttons>
                  <Button
                    button_type="pale_blue"
                    padding="6px 16px"
                    fontSize="11px"
                    width="max-content"
                    onClick={() => handleGenDocument({ id: row.id })}
                  >
                    {row.method === 'INVOICE' ? '請求書再発行' : '請求書兼領収書再発行'}
                  </Button>
                </Buttons>
              ),
            },
          ]}
          data={data.payments.map((p) => ({
            ...p,
            id: p.id,
            method: p.method,
            line_items: p.line_items,
            created_at: p.created_at,
          }))}
          countPerPageOptions={[200]}
        />
      </div>

      {requestDocumentModal.createModal(
        <div
          style={{
            padding: '20px',
          }}
        >
          <CompanyMfDepartmentSelect
            selectedDepartmentId={selectedDepartmentId}
            moneyForwardDepartments={moneyForwardDepartments}
            refetchDepartments={refetchDepartments}
            loading={departmentsRefetching}
            onSelect={(id: string) => {
              setSelectedDepartmentId(id);
            }}
            title="請求書の記載する宛名を選択"
          />
          <div
            style={{
              marginTop: '1rem',
              display: 'flex',
              gap: '1rem',
              justifyContent: 'end',
            }}
          >
            <Button disabled={fetching} button_type="blue" onClick={handleRequestDocument}>
              請求書を発行する
            </Button>
            <Button
              disabled={fetching}
              button_type="pale_blue"
              onClick={() => {
                requestDocumentModal.closeModal();
              }}
            >
              キャンセル
            </Button>
          </div>
        </div>,
      )}
    </Wrapper>
  );
};
