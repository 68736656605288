import { useContext } from 'react';
import styled from '@emotion/styled';
import { authContext } from 'application/contexts/useAuth';
import { NextReward } from 'ui/components/user/patterns/NextReward/NextReward';
import { ProfileCoverage } from 'ui/components/user/patterns/ProfileCoverage/ProfileCoverage';
import { UserIcon } from 'ui/components/user/elements/UserIcon/UserIcon';

const Card = styled.div`
  position: relative;
  margin-top: 36px;
  padding: 36px 16px 16px;
  background-color: var(--spr-white);
  border-radius: 16px;
  box-shadow: 0px 2px 4px 0px #0000000d;
`;

const Icon = styled.div`
  position: absolute;
  top: -36px;
  left: 50%;
  transform: translateX(-50%);
`;

const Name = styled.p`
  margin: 8px 0 16px;
  font-size: 14px;
  font-weight: 700;
  text-align: center;
`;

/**
 * サイドメニュー プロフィール情報
 */
export const SideProfile = () => {
  const { user } = useContext(authContext);
  return (
    <Card>
      <Icon>
        <UserIcon src={user.icon_image} size={72} />
      </Icon>
      <Name>{user.spreader_name || `${user.last_name} ${user.first_name}`}</Name>
      <NextReward />
      <ProfileCoverage />
    </Card>
  );
};
