import { FC } from 'react';
import styled from '@emotion/styled';
import { mq } from 'utils/responsive';
import { BackButton } from 'ui/components/publicPages/elements/BackButton/BackButton';

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
`;

const Content = styled.div`
  max-width: 1024px;
  margin: 0 auto;
  padding-block: 185px 80px;
`;

const Padding = styled.div`
  max-width: 1024px;
  margin: 0 auto;
  padding: 0 20px;
  ${mq('NARROW')} {
    padding: 0 20px;
  }
  ${mq('SP')} {
    padding: 0 20px;
  }
`;

const Title = styled.h3`
  font-size: var(--font-size--xxx-large);
  font-weight: var(--font-weight--bolder);
  position: relative;
  text-align: center;
  line-height: 1.5;
  margin: 0 auto 50px;
  padding-bottom: 30px;

  ${mq('SP')} {
    white-space: pre-line;
    word-wrap: break-word;
  }

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    display: block;
    background: var(--spr-black);
    height: 4px;
    width: 91px;
    border-radius: 2px;
  }
`;

const Body = styled.div`
  white-space: pre-wrap;
  word-wrap: break-word;
  line-height: 1.5em;
  font-size: var(--font-size--medium);
`;

const History = styled.div`
  line-height: 1.5em;
  font-size: var(--font-size--medium);
  text-align: right;
`;

const Margin = styled.div`
  margin-block: 50px;
`;

export const CompanyTermsContent: FC = () => {
  return (
    <>
      <Wrapper>
        <Content>
          <Padding>
            <Title>
              {`Spreadyサービス
              利用基本規約
              （法人様向け規約）
              `}
            </Title>
            <Body>
              {`
申込者（以下「甲」という。）は、Spready株式会社（以下「乙」という。）が提供する、甲の社外人脈の拡大を支援するサービスの利用の基本的事項に関し、以下の規約（以下「本規約」という。）に同意する。

第1条（目的）
本規約は甲の社外人脈の拡大支援に関する甲と乙の間の権利義務関係を定めることを目的とし、当事者間の社外人脈の拡大支援の業務に関する全ての法律関係に適用されるものとする。

第2条（定義）
「本業務」とは、乙の甲に対する「Spreadyに登録する個人ユーザを活用した、自社人脈ネットワークの拡大」の支援業務を意味する。

第3条（契約）
1. 乙は甲に対し、本規約に基づく本業務を行い、甲は本規約に基づき本業務の対価を乙に支払う。
2. 本規約に基づく甲乙間の契約は、甲が、オンライン上で、乙が定める申込みに必要な事項（以下「必要事項」という。）を入力し、本規約に同意することにより、本規約に基づく契約（以下「本契約」という。）が成立する。
3. 甲は必要事項として入力した情報は全て真実であることを保証する。

第4条（個別契約）
1. 本契約に定める事項は、本契約の有効期間中に、甲及び乙間で行われる個別の本業務に関する契約（以下、「個別契約」という。）に共通に適用される。ただし、個別契約において本規約と異なる事項を定めたときは、個別契約の定めが優先して適用される。
2. 乙は、個別契約に基づき、本業務として以下の業務を行う。
(1) 甲がマッチングを希望する人材を募集する情報（以下、「案件情報」という。）を乙のプラットフォーム上に掲載する業務
(2) 前号の業務に関するサポート業務
(3) 前各号に定める業務の他に個別契約に定める業務及びこれらに付随する業務
3. 甲が個別契約に基づき取得するチケット（以下、「チケット」という。）は、案件情報に基づき、人材とマッチングし、当該人材に対する連絡を開始することを条件に消費されるものとする。
4. 案件情報の掲載条件、掲載済みの案件情報の修正条件、掲載費用、チケットの利用条件その他の条件は、個別契約で定めるものとする。
5. 個別契約は、別途乙が定めるサービスの案内資料、見積書、発注書、発注請書、請求書等に基づき締結するものとする。

第5条（本業務の対価）
1. 甲は、乙に対し本業務の対価として、本規約又は個別契約で定めた料金を支払うものとする。
2. 甲は、本業務の対価の支払期限は、乙が発行する請求書に定める。
3. 本業務の対価の支払方法は、乙が指定する口座への銀行振込とする。ただし、銀行振込手数料その他支払に要する費用は甲の負担とする。

第6条（チケットの取扱い）
1. チケットの有効期限は、購入日から12ヵ月とする。ただし、個別契約で異なる有効期限を定めた場合は、個別契約に定める有効期限が優先されるものとする。
2. 前項の規定に拘わらず、本契約が終了した場合、甲が保有する未使用のチケットは、消費されたものとみなす。
3. チケットは払い戻しができないものとする。

第7条（報告及び協議）
乙は、甲からの指示がある場合には、本業務に関する打ち合わせを開催し又はこれに出席する。

第8条（損害賠償）
1. 甲及び乙が、本規約に関連して相手方に損害を及ぼした場合には、相手方に対してその損害を賠償する責任を負う。ただし、乙は、逸失利益、事業機会の喪失、データの喪失、事業の中断、その他間接損害、特別損害、派生的損害及び付随的損害については賠償する責任を負わない。
2. 甲及び乙は、本規約に基づく、金銭債務の支払を遅滞した場合は、支払遅延日数に応じて年14.6％の割合で遅延損害金を支払わなければならない。

第9条（免責）
乙は、以下の事由により甲又はその関係者に発生した損害については、債務不履行責任、不法行為責任、その他の法律上の請求原因の如何を問わず一切賠償の責任を負わない。
(1) 乙又は乙の委託先が管理する機器の障害又はこれに係る通信環境、不正アクセス若しくはコンピュータウィルス等に起因する障害
(2) 本業務の運用上又は技術上の理由でやむを得ない場合
(3) その他天災地変等不可抗力による障害
(4) データの消失
(5) 甲とマッチングした人材その他の第三者との間に生じたトラブル

第10条（法令の遵守）
甲及び乙は、本規約に基づく業務を遂行するにあたっては、関連する法令を遵守するものとする。

第11条（有効期間）
1. 本契約の有効期間は、本契約の成立日から1年間とする。ただし、有効期間満了の1ヵ月前までにいずれの当事者から何らの申し入れのないときはさらに1年間自動的に延長されるものとし、以後も同様とする。
2. 前項の規定に拘わらず、甲の案件情報のうち最新の案件情報の掲載期間が終了してから1年以内に、乙が甲に対し、新たな案件情報の掲載を依頼しない場合、本契約は終了するものとする。
3. 乙は、本契約が終了した場合は、案件情報その他の乙が保有する甲に関する情報を、削除することができるものとする。

第12条（秘密保持）
1. 本規約において「秘密情報」とは、本規約に関連して、一方当事者が、相手方より口頭、書面その他の記録媒体等により提供若しくは開示されたか又は知り得た、相手方の技術、営業、業務、財務、組織、その他の事項に関する全ての情報を意味する。ただし、a.相手方から提供若しくは開示がなされたとき又は知得したときに、既に一般に公知となっていた、又は、既に知得していたもの、b.相手方から提供若しくは開示がなされた後又は知得した後、自己の責に帰せざる事由により刊行物その他により公知となったもの、c.提供又は開示の権限のある第三者から秘密保持義務を負わされることなく適法に取得したもの、d.秘密情報によることなく単独で開発したもの、e.相手方から秘密保持の必要なき旨書面で確認されたものについては、秘密情報から除外する。
2. 本規約の当事者は、秘密情報を本規約の目的のみに利用するとともに、相手方の書面による承諾なしに第三者に相手方の秘密情報を提供、開示又は漏洩しないものとする。
3. 前項の規定に拘わらず、本規約の当事者は、法律、裁判所又は政府機関の命令、要求又は要請に基づき、相手方の秘密情報を開示することができる。ただし、当該命令、要求又は要請があった場合、速やかにその旨を相手方に通知しなければならない。
4. 本規約の当事者は、秘密情報を記載した書面その他の記録媒体等を複製する場合には、事前に相手方の承諾を得ることとし、複製物については第2項に準じて取り扱うものとする｡
5. 本規約の当事者は、本契約の終了時又は相手方から求められた場合にはいつでも、遅滞なく、相手方の指示に従い、秘密情報並びに秘密情報を記載又は包含した書面、その他の記録媒体及びその全ての複製物を返却又は廃棄する。
6. 甲は、マッチングした人材に対し提供する情報について、自らの責任において、取捨選択をして情報を開示するものとする。なお、乙は、当該人材に対し、当該人材との初回面談において甲が当該人材に対し提供する情報について、当該面談以外の目的で使用することができず、第三者に開示又は漏洩をしてはならない義務を課するものとする。
7. 本規約の有効期間の満了又は本規約の解除若しくは解約により、本契約が終了した後も本条は有効とする。

第13条（個人情報の取り扱い）
甲及び乙は、本業務に関して知り得た相手方の顧客及び取引先等の氏名、住所、生年月日、電話番号その他個人情報（個人情報の保護に関する法律（平成15年5月30日法律第57号。以下「個人情報保護法」という。）第2条第1項に定義されるものをいう。以下同じ。）の取扱いに関して、以下の内容を遵守する。
(1) 本規約の目的以外のいかなる目的にも個人情報を利用してはならない。
(2) 相手方の事前の承諾なく、個人情報を複写、複製してはならない。
(3) 個人情報は全て、前条の秘密情報として取り扱うものとする。
(4) 本業務終了時等、本業務の履行に際して個人情報を利用する必要がなくなった場合又は相手方からの指示があった場合、個人情報及びこれが記録された媒体を直ちに相手方に返却、消去又は廃棄しなければならない。
(5) 個人情報への不正なアクセス又は個人情報の紛失、破壊、盗難、改ざん若しくは漏洩等の事故が生じたときは、直ちに相手方に通知を行うと共に、被害の拡大を防ぐ措置をとり、相手方の指示に従ってその他必要な措置を実施しなければならない。
(6) 個人情報保護法、関連法令及び金融分野における個人情報保護に関するガイドライン等の定めに従い、個人情報への不当なアクセス又は個人情報の紛失、破壊、盗難、改ざん若しくは漏洩等の危険に対する組織的、人的、物理的、技術的安全管理措置をとるとともに、必要かつ適切な管理・責任体制を構築するものとする。

第14条（禁止事項）
1. 甲は、乙のサービスを利用するに際して以下に記載する行為を行なわないことを保証するものとする。
(1) 乙又は第三者の権利(著作権、商標権、特許権等の知的財産権、名誉権、プライバシー権、その他法令上または本契約上の権利を広く含む)を害する行為。
(2) 虚偽の情報を故意に登録・投稿する行為。
(3) 自己のアカウントを他人に利用させるなどのアカウントを不正に利用する行為。
(4) 乙のサービスを介して第三者と知り合ったにも関わらず、本業務の対価等を支払わずに、乙の関知しないところで連絡を取る行為。
(5) 乙の業務に支障を与える行為。
(6) 本業務に使用するソフトウェア又はシステムの誤作動を誘引する行為、本業務の運営妨害行為。
(7) 本業務に使用するソフトウェアの逆アセンブル、逆コンパイル、リバースエンジニアリング、その他当該ソフトウェアのソースコードを解析する行為。
(8) 本業務に使用するシステムに権限なく不正にアクセスし、又は当社設備に蓄積された情報を不正に書き換え・消去する行為。
(9) ウイルスの送付、不正アクセス等により、乙のシステムやサービス提供に障害等を引き起こす行為。
(10) 宗教活動または宗教団体への勧誘行為。
(11) ネットワークビジネス、マルチ商法、ねずみ講などへの勧誘行為。
(12) 乙のサービスを利用したアフィリエイト誘導、目的に合致しないリンクの貼付け行為。
(13) 法令、公序良俗に反する行為、そのおそれがある行為。
(14) 本規約又は本サービスの趣旨又は目的に反する行為。
(15) 本サービス、当社又は利用者の情報を収集する行為。
(16) その他乙が不適切であると判断する行為。
2. 甲が前項第4号に該当する行為を行った場合は、甲は乙に対し賠償金として乙が本業務の対価として支払った金額の3倍の金額を支払うこととし、甲はあらかじめこれを承諾するものとする。

第15条 (アカウント情報の管理等)
1. 甲はアカウント情報を自ら管理する責任を負う。甲は、アカウント情報を第三者等に利用させたり、譲渡や売買、質入、貸与、賃貸したり、その他形態を問わず処分することはできない。
2. アカウント情報の管理不十分による情報の漏洩、使用上の過誤、第三者の使用、不正アクセス等による損害の責任は甲が負うものとし、乙は一切責任を負わない。
3. 甲は、アカウント情報が第三者に漏えいした場合又はそのおそれがある場合、速やかに乙に連絡するものとする。

第16条（反社会的勢力の排除）
1. 甲及び乙は、本契約の成立日において、自己、自己の取締役、監査役、執行役員等の業務執行について重要な地位にある者（以下「役職員等」という。）、子会社・関連会社（それぞれ財務諸表等の用語、様式及び作成方法に関する規則（昭和38年11月27日大蔵省令第59号）第8条第3項及び第5項に規定される意味を有するものとする。）及びそれらの役職員等、並びに出資者が、以下の各号に定める者（以下「暴力団等」という。）に該当していないことを表明し、保証する。
(1) 暴力団（暴力団員による不当な行為の防止等に関する法律（平成3年5月15日法律第77号。その後の改正を含む。）第2条において定義される。以下同様とする。）
(2) 暴力団の構成員（準構成員を含む。以下同様とする。）、又は暴力団の構成員でなくなった日から5年を経過しない者
(3) 暴力団関係企業又は本項各号に定める者が出資者である、若しくは業務執行について重要な地位にある団体、又はこれらの団体の構成員
(4) 総会屋、社会運動標榜ゴロ、政治活動標榜ゴロ、特殊知能暴力集団又はこれらの団体の構成員
(5) 暴力団又は暴力団の構成員と密接な関係を有する者
(6) 前各号に準じる者
2. 甲及び乙は、相手方に対して、本契約の成立日において、自己、自己の役職員等、子会社・関連会社及びそれらの役職員等、並びに出資者が、以下の各号のいずれにも該当していないことを表明し、保証する。
(1) 暴力団等が経営を支配していると認められる関係を有すること
(2) 暴力団等が経営に実質的に関与していると認められる関係を有すること
(3) 自己若しくは第三者の不正の利益を図る目的、又は第三者に損害等を加える目的をもってする等、不当に暴力団等を利用していると認められる関係を有すること
(4) 暴力団等に対して資金等を提供し、又は便宜を供与する等の関与をしていると認められる関係を有すること
(5) 暴力団等と社会的に非難されるべき関係を有すること
(6) 前各号に準じる関係を有すること
3. 甲及び乙は、自ら又は第三者をして以下の各号に定める行為及びそれらのおそれのある行為を行わないことを誓約する。
(1) 暴力的な要求行為
(2) 法的な責任を超えた不当な要求行為
(3) 脅迫的な言動を行い、又は暴力を用いる行為
(4) 風説の流布、偽計若しくは威力を用いて相手方の信用を毀損し、又は相手方の業務を妨害する行為
(5) 方法及び態様の如何を問わず暴力団等と関与する行為
(6) 前各号に準じる行為
4. 甲及び乙は、相手方の取引先（取引が数次にわたる場合は、そのすべてを含む。以下同様とする。）が暴力団等であること、若しくは第2項各号に定める関係を有していること、又は相手方の取引先が第3項に定める行為を行ったことが判明した場合は、当該取引先との契約の解除その他の反社会的勢力の排除のために必要となる措置を講じることを要請することができるものとし、相手方は当該措置を講じることを誓約するものとする。
5. 甲及び乙は、本契約の成立日後に、①相手方において第1項及び第2項に規定する表明及び保証事項が虚偽若しくは不正確となる事由が判明若しくは発生し、若しくは発生すると合理的に見込まれる場合、又は②相手方が第3項及び第4項に規定する誓約に違反する事由が判明若しくは発生した場合には、通知、催告その他の手続きを要することなく、直ちに、相手方と締結した全ての契約を解除することができるものとする。
6. 本条による解除によっては、解除当事者の被解除当事者に対する損害賠償請求は何ら妨げられないものとする。また本条による解除によって被解除当事者に損害等が発生した場合でも解除当事者は一切責任を負わないものとする。

第17条（解除）
1. 甲及び乙は、相手方に下記のいずれかの事由が生じた場合には、事前の通知・催促を要せず、直ちに書面によって本規約を解除することができる。
(1) 甲が第14条第1項に違反したとき（乙からの解除に限る）
(2) 本規約に基づく義務を履行せず、4週間以上の期間を置いた催告にもかかわらず、当該義務を履行しなかったとき。
(3) 振出又は裏書署名した小切手又は手形が不渡り処分を受けたとき、その他銀行取引停止処分を受けたとき。
(4) 仮差押、仮処分、強制執行、担保権実行の申立、又は滞納処分を受けたとき。
(5) 破産、民事再生、会社更生又は特別清算の申立がなされたとき。
(6) 解散決議又は営業の廃止がされたとき。
(7) 信用や名誉を毀損する行為を行ったとき。
(8) その他、本契約を継続することができない重大な事由が生じたとき。
2. 甲又は乙が契約期間途中で本契約を解除するときは、2ヶ月前までに書面で通知するものとする。甲が中途で解約する場合、乙はすでに支払われた料金を返還する義務を負わないものとする。

第18条 （不可抗力）
地震、台風、津波その他の天変地異、戦争、暴動、内乱、テロ行為、疫病、法令・規則の制定・改廃、公権力による命令・処分その他の政府による行為、争議行為、輸送機関・通信回線等の事故、その他不可抗力による契約の全部又は一部の履行遅滞又は履行不能については、甲及び乙は何ら責任を負わない。

第19条（通知）
1. 本規約に基づく又はこれに関連する全ての通知は、電子メール又は指定のオンラインツールにより必要事項として入力された相手方の宛先に対して行うものとする。なお、いずれの当事者も本項に基づき相手方に通知することにより、通知先を変更することができる。
2. 前項に基づく通知が、相手方の所在不明等相手方の責に帰すべき事由により、到達しなかった場合には、その発送の日から2週間を経過した日に、当該通知が到達したものとみなす｡

第20条（準拠法及び合意管轄）
本規約の準拠法は日本法とし、本規約に関連して生じた紛争については、東京地方裁判所を第一審の専属的合意管轄裁判所とする。

第21条（協議事項）
本規約に定めのない事項及び解釈の疑義については、法令の規定並びに慣習に従うほか、両当事者誠意をもって協議解決を図るものとする。
              `}
            </Body>

            <History>
              <Margin>以上</Margin>
              <Margin>
                ２０１９年０５月３０日作成
                <br />
                ２０２２年０３月０１日改訂
                <br />
                ２０２３年０４月０１日改訂
                <br />
                ２０２３年０７月２５日改訂
                <br />
                ２０２４年０６月２７日改訂
              </Margin>
            </History>
          </Padding>
        </Content>
      </Wrapper>
      <BackButton />
    </>
  );
};
