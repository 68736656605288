import { useRef } from 'react';
import { Outlet } from 'react-router-dom';
import styled from '@emotion/styled';
import { Header } from 'ui/components/user/panes/Header/Header';
import { Side } from 'ui/components/user/panes/Side/Side';
import { UserDrawer } from 'ui/components/user/screens/drawers/UserDrawer/UserDrawer';
import { MobileMenu } from 'ui/components/user/panes/MobileMenu/MobileMenu';
import { Tutorial } from 'ui/components/user/screens/modals/Tutorial/Tutorial';
import { StampCongrats } from 'ui/components/user/screens/modals/StampCongrats/StampCongrats';
import { ScrollTop } from 'ui/components/user/elements/ScrollTop/ScrollTop';
import { useUserStandard } from './useUserStandard';
import { TermsAgreement } from 'ui/components/user/screens/modals/TermsAgreement/TermsAgreement';
import { mqUser } from 'utils/responsive';

const Wrapper = styled.div`
  position: relative;
  background: var(--gray-light-3);
`;

const Body = styled.div<{ isHiddenSide: boolean }>`
  display: ${(props) => (props.isHiddenSide ? 'block' : 'grid')};
  grid-template-columns: var(--width-sidebar) var(--width-main);
  place-content: start center;
  column-gap: 40px;
  height: calc(100vh - var(--height-user-pc-header));
  @supports (height: 100svh) {
    height: calc(100svh - var(--height-user-pc-header));
  }
  padding: 30px;
  overflow-y: auto;
  ${mqUser('NARROW')} {
    place-content: unset;
    column-gap: 20px;
    padding: 20px;
  }

  ${mqUser('SP')} {
    display: block;
    column-gap: 0;
    padding: 20px 0 30px;
  }
`;

export const UserStandard: React.FC = () => {
  const bodyRef = useRef<HTMLDivElement>(null);
  const { canEnter, isHiddenSide } = useUserStandard();

  if (!canEnter) {
    return null;
  }

  return (
    <Wrapper>
      <ScrollTop body={bodyRef.current} />

      <Header />
      <Body id="window-top-position" ref={bodyRef} isHiddenSide={isHiddenSide}>
        {!isHiddenSide && <Side />}
        <Outlet />
      </Body>
      <UserDrawer />
      <MobileMenu />
      <Tutorial />
      <StampCongrats />
      <TermsAgreement />
    </Wrapper>
  );
};
