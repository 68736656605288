import { fetchFixture } from 'utils/fetchFixture';
import fixture from './CompanyApplicationForm.post.json';
import { API_LOCATION_SPREADY } from 'infrastructure/config';
import {
  ICompanyApplicationFormClient,
  IPostInput,
  IPostResponse,
} from 'interfaceAdapter/gatewaySchemas/ICompanyApplicationFormClient';

const PATH = `${API_LOCATION_SPREADY}/company/new/application_form`;

export class CompanyApplicationFormClient implements ICompanyApplicationFormClient {
  async post(input: IPostInput): Promise<IPostResponse> {
    const { urlId, mock = false } = input;
    const method = 'POST';
    const headers = {
      'Content-Type': 'application/json',
    };
    const body = JSON.stringify({ url_id: urlId });

    try {
      const response = mock
        ? await fetchFixture(fixture)
        : await fetch(PATH, {
            method,
            headers,
            body,
          });
      const status = response.status;
      const { data, message } = await response.json();

      return {
        data,
        message,
        status,
      };
    } catch {
      throw new Error('処理に失敗しました');
    }
  }
}
