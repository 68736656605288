import { API_LOCATION_SPREADY } from 'infrastructure/config';
import {
  ICompanyApplyClient,
  IPostInput,
  IPostResponse,
} from 'interfaceAdapter/gatewaySchemas/ICompanyApplyClient';
import { HttpBadRequest } from 'domain/types/httpResponse';

const PATH = `${API_LOCATION_SPREADY}/company/new/submit_application`;

export class CompanyApplyClient implements ICompanyApplyClient {
  async post(input: IPostInput): Promise<IPostResponse> {
    const { companyApplication } = input;
    const method = 'POST';
    const headers = {
      'Content-Type': 'application/json',
    };
    const body = JSON.stringify(companyApplication);

    try {
      const response = await fetch(PATH, {
        method,
        headers,
        body,
      });
      const status = response.status;
      const message = status === HttpBadRequest.status ? await response.json() : undefined;

      return {
        message,
        status,
      };
    } catch (e) {
      throw new Error('処理に失敗しました');
    }
  }
}
