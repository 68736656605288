import dayjs from 'dayjs';
import * as yup from 'yup';
import { IProject } from 'domain/entities/Project/Project';
import { AnswerCompanyUserSchema } from 'domain/entities/factories/Casting';
import { User } from 'domain/entities/User/User';

export interface FeedbackToSpreader {
  answer_company_user?: AnswerCompanyUserSchema | null;
  answer_text: string | null;
}

export const enum CASTING_STATUS {
  ISSUE_INVITE_URL = 'ISSUE_INVITE_URL',
  JUST_INVITED = 'JUST_INVITED',
  WAITING_REVIEW = 'WAITING_REVIEW',
  WAITING_FIRST_CONTACT = 'WAITING_FIRST_CONTACT',
  BEFORE_SCHEDULING = 'BEFORE_SCHEDULING',
  SCHEDULING = 'SCHEDULING',
  SCHEDULED = 'SCHEDULED',
  CANCELED_BY_INTRODUCED = 'CANCELED_BY_INTRODUCED',
  CANCELED_BY_COMPANY = 'CANCELED_BY_COMPANY',
  CANCELED_BY_ADMIN = 'CANCELED_BY_ADMIN',
}

type CastingStatus = keyof typeof CASTING_STATUS;

export const CASTING_STATUS_DICT: { [key: string]: number | CastingStatus } = {
  [CASTING_STATUS.JUST_INVITED]: -20,
  [CASTING_STATUS.WAITING_REVIEW]: -10,
  [CASTING_STATUS.WAITING_FIRST_CONTACT]: -5,
  [CASTING_STATUS.BEFORE_SCHEDULING]: 0,
  [CASTING_STATUS.SCHEDULING]: 10,
  [CASTING_STATUS.SCHEDULED]: 20,
  [CASTING_STATUS.CANCELED_BY_INTRODUCED]: 30,
  [CASTING_STATUS.CANCELED_BY_COMPANY]: 40,
  [CASTING_STATUS.CANCELED_BY_ADMIN]: 50,
  '-20': CASTING_STATUS.JUST_INVITED,
  '-10': CASTING_STATUS.WAITING_REVIEW,
  '-5': CASTING_STATUS.WAITING_FIRST_CONTACT,
  '0': CASTING_STATUS.BEFORE_SCHEDULING,
  '10': CASTING_STATUS.SCHEDULING,
  '20': CASTING_STATUS.SCHEDULED,
  '30': CASTING_STATUS.CANCELED_BY_INTRODUCED,
  '40': CASTING_STATUS.CANCELED_BY_COMPANY,
  '50': CASTING_STATUS.CANCELED_BY_ADMIN,
} as const;

export const statusFilterMap = {
  all: [
    CASTING_STATUS_DICT[CASTING_STATUS.WAITING_FIRST_CONTACT],
    CASTING_STATUS_DICT[CASTING_STATUS.BEFORE_SCHEDULING],
    CASTING_STATUS_DICT[CASTING_STATUS.SCHEDULING],
    CASTING_STATUS_DICT[CASTING_STATUS.SCHEDULED],
  ] as number[],
  waitingContact: [CASTING_STATUS_DICT[CASTING_STATUS.WAITING_FIRST_CONTACT]] as number[],
  scheduling: [
    CASTING_STATUS_DICT[CASTING_STATUS.BEFORE_SCHEDULING],
    CASTING_STATUS_DICT[CASTING_STATUS.SCHEDULING],
  ] as number[],
  fixed: [CASTING_STATUS_DICT[CASTING_STATUS.SCHEDULED]] as number[],
} as const;

export type CastingStatusKey = keyof typeof statusFilterMap;

export type CastingAssignKey = 'me' | 'all';

export enum CASTING_STATUS_DISPLAY_TEXT_DICT {
  ISSUE_INVITE_URL = '',
  JUST_INVITED = '招待受理',
  WAITING_REVIEW = '承認待ち',
  WAITING_FIRST_CONTACT = '企業からの連絡待ち',
  BEFORE_SCHEDULING = 'スケジュール調整中',
  SCHEDULING = 'スケジュール調整中',
  SCHEDULED = '面談日確定',
  CANCELED_BY_INTRODUCED = 'キャンセル',
  CANCELED_BY_COMPANY = 'キャンセル',
  CANCELED_BY_ADMIN = 'キャンセル',
}

export enum DECLINE_REASON {
  ALREADY_KNOWN = 'decline_reason_already_known',
  NOT_MATCH = 'decline_reason_not_match',
  OTHER = 'decline_reason_other',
  CLOSED = 'decline_reason_closed',
  NONE_PRIORITY = 'decline_reason_none_priority',
}
export const DECLINE_REASONS = Object.values(DECLINE_REASON);

export enum DECLINE_REASON_DISPLAY_TEXT_DICT {
  ALREADY_KNOWN = '既に面識がある方のため',
  NOT_MATCH = '要件と見合わないため',
  CLOSED = 'セッションがクローズしたため',
  NONE_PRIORITY = '優先したい候補者が他にいるため',
  OTHER = 'その他',
}
export const DECLINE_REASONS_DISPLAY_TEXTS = Object.values(DECLINE_REASON_DISPLAY_TEXT_DICT);

export const enum CASTING_TYPES {
  INVITED = 'invited',
  SELF_APPLIED = 'selfApplied',
  INVITING = 'inviting',
}

export type CastingTypes = (typeof CASTING_TYPES)[keyof typeof CASTING_TYPES];

export const castingTypeTitle = {
  [CASTING_TYPES.INVITING]: 'セッション応募の流れ',
  [CASTING_TYPES.INVITED]: 'ゲストをさそって応募しよう',
  [CASTING_TYPES.SELF_APPLIED]: '自分で応募する',
};

export interface ICastingUser {
  id: number;
  spreader_name?: string | null;
  last_name: string;
  first_name: string;
  company_job?: string | null;
  company_name?: string | null;
  icon_image?: string | null;
  urls?: string[];
  age?: number;
  profiee_url?: string | null;
  facebook_url?: string | null;
}

export interface ICasting {
  id: number;
  project: IProject | null;
  spreader: ICastingUser | User | null;
  introduced_user: ICastingUser | User | null;
  message_from_spreader?: string;
  status: CastingStatus | null;
  note?: string | null;
  meetup_date_at: Date | null;
  assigned_at: Date | null;
  chat_room_id: string | null;
  survey_casted_answer_at?: Date | null;
  survey_company_user_answer_at?: Date | null;
  feedback_to_spreader_by_company?: FeedbackToSpreader | null;
  passed_days?: number | null;
  match_points: Array<MatchPoint>;
  exists_on_crm_list: boolean | null;
  is_chat_archived?: boolean | null;
  meetup_finished?: boolean;
  decline_message?: string | null;
  created_at: Date;

  is_canceled: boolean;
  is_chat_available: boolean;

  /**
   * アンケート回答を受付中かどうか
   * ただし紹介の場合はそもそもアンケート回答はしない。面談か紹介かはDB管理されていない点に留意。
   */
  is_survey_open: boolean;
}

export const answerCompanyUserSchema = {
  id: yup.number().required(),
  last_name: yup.string().required(),
  first_name: yup.string().required(),
  icon_image: yup.string().nullable(),
  status: yup.number().required(),
  company: yup
    .object({
      id: yup.number().required(),
      name: yup.string().required(),
      icon_image: yup.string().nullable(),
      logo_display_pushed: yup.boolean().required(),
      search_words: yup.string().nullable(),
      status: yup.number().required(),
    })
    .required(),
};

export interface MatchPoint {
  question: string;
  answer: string;
}

export class Casting implements ICasting {
  constructor(
    public id: number,
    public project: IProject | null,
    public spreader: ICastingUser | User | null,
    public introduced_user: ICastingUser | User | null,
    public message_from_spreader: string,
    public status: CastingStatus | null,
    public note: string,
    public meetup_date_at: Date | null,
    public assigned_at: Date | null,
    public chat_room_id: string | null,
    public survey_casted_answer_at: Date | null,
    public survey_company_user_answer_at: Date | null,
    public feedback_to_spreader_by_company: FeedbackToSpreader | null,
    public passed_days: number | null,
    public match_points: Array<MatchPoint>,
    public description: string,
    public exists_on_crm_list: boolean | null,
    public is_chat_archived: boolean | null,
    public created_at: Date,
    public meetup_finished?: boolean,
    public decline_message?: string | null,
  ) {}

  get is_canceled(): boolean {
    return (
      this.status === CASTING_STATUS.CANCELED_BY_ADMIN ||
      this.status === CASTING_STATUS.CANCELED_BY_COMPANY ||
      this.status === CASTING_STATUS.CANCELED_BY_INTRODUCED
    );
  }

  get is_chat_available(): boolean {
    // ステータス不明
    if (!this.status) {
      return false;
    }
    // まだチャットが可能なステータスではない
    if (CASTING_STATUS_DICT[this.status] < CASTING_STATUS_DICT[CASTING_STATUS.BEFORE_SCHEDULING]) {
      return false;
    }
    // ChatRoomID 未発行
    if (this.chat_room_id === null) {
      return false;
    }
    return true;
  }

  get is_survey_open(): boolean {
    // 面談日が未設定
    if (this.meetup_date_at === null) {
      return false;
    }
    // まだ面談日が終わっていない
    if (dayjs(this.meetup_date_at).isAfter(dayjs(), 'day')) {
      return false;
    }
    // アンケート回答済み
    if (this.survey_casted_answer_at !== null) {
      return false;
    }
    return true;
  }
}
