import { inject, injectable } from 'tsyringe';
import { buildResponse, HttpBadRequest } from 'domain/types/httpResponse';
import type { ICompanyApplicationQuery } from 'application/querySchemas/ICompanyApplicationQuery';
import type { ICompanyApplicationFactory } from 'domain/entities/factories/CompanyApplication';
import type { ICompanyApplication } from 'domain/entities/CompanyApplication/CompanyApplication';
import type { ICompanyApplicationFormClient } from 'interfaceAdapter/gatewaySchemas/ICompanyApplicationFormClient';

@injectable()
export class CompanyApplicationQuery implements ICompanyApplicationQuery {
  constructor(
    @inject('ICompanyApplicationFormClient')
    public readonly companyApplicationFormClient: ICompanyApplicationFormClient,
    @inject('ICompanyApplicationFactory') public readonly factory: ICompanyApplicationFactory,
  ) {}

  public async fetch(urlId: string): Promise<ICompanyApplication | HttpBadRequest> {
    const { data, message, status } = await this.companyApplicationFormClient.post({ urlId });

    const httpResponse = buildResponse(status, message);
    if (httpResponse instanceof HttpBadRequest) {
      return httpResponse;
    }

    return this.factory.build(data.form_input);
  }
}
