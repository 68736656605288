import { CompanyApplication, ICompanyApplication } from '../CompanyApplication/CompanyApplication';
import yup from 'utils/yup';

export interface ICompanyApplicationFactory {
  build(data: CompanyApplicationCore): ICompanyApplication;
}

export const companyApplicationCoreSchema = {
  url_id: yup
    .string()
    .matches(/^[a-z\-0-9]{5,50}$/)
    .default('')
    .required(),
  company_name: yup.string().max(100).default('').required(),
  company_zipcode: yup.string().zipCode().default('').required(),
  company_address: yup.string().max(200).default('').required(),
  charge_last_name: yup.string().default('').required(),
  charge_first_name: yup.string().default('').required(),
  charge_email: yup.string().default('').required().email(),
  logo_use_enable: yup.boolean().default(true).required(),
  description: yup.string().nullable().default('').optional(),
};
const coreSchema = yup.object(companyApplicationCoreSchema).required();
export type CompanyApplicationCore = yup.InferType<typeof coreSchema>;

export class CompanyApplicationFactory implements ICompanyApplicationFactory {
  public build(data: CompanyApplicationCore) {
    const {
      url_id,
      company_name,
      company_zipcode,
      company_address,
      charge_last_name,
      charge_first_name,
      charge_email,
      logo_use_enable,
      description,
    } = data;

    return new CompanyApplication(
      url_id,
      company_name,
      company_zipcode,
      company_address,
      charge_last_name,
      charge_first_name,
      charge_email,
      logo_use_enable,
      description ?? null,
    );
  }
}
