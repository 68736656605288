import { authContext } from 'application/contexts/useAuth';
import { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { HttpBadRequest } from 'domain/types/httpResponse';

export const useLoginFacebook = (): void => {
  const navigate = useNavigate();
  const { urlAfterLogin, restoreCastInfo, loginByFacebook } = useContext(authContext);

  useEffect(() => {
    const login = async () => {
      const castInfo = restoreCastInfo();
      try {
        const fbToken = await loginByFacebook(castInfo);
        if (fbToken !== null) {
          navigate(`/register/?facebook_sign_up_token=${fbToken}`);
          return;
        } else if (castInfo.isInvited) {
          navigate('/user/casting/casted/');
          return;
        } else if (castInfo.isIntroduced) {
          navigate('/user/casting/');
          return;
        }

        navigate(urlAfterLogin);
      } catch (error) {
        if (error instanceof HttpBadRequest) {
          navigate(`/login/?message=${error.message}`);
          return;
        }

        navigate(`/login/?message=登録・ログインに失敗しました`);
      }
    };

    login();
  }, []);
};
