import CompanyContextConfig from 'CompanyContextConfig';
import PublicPageContextConfig from 'PublicPageContextConfig';
import UserContextConfig from 'UserContextConfig';
import { FC } from 'react';
import { Navigate, RouterProvider, createBrowserRouter } from 'react-router-dom';
import { CompanyStandard } from 'ui/components/company/layouts/CompanyStandard/CompanyStandard';
import CompanyPasswordReset from 'ui/components/company/layouts/PasswordReset/PasswordReset';
import { PublicPage } from 'ui/components/publicPages/layouts/PublicPage/PublicPage';
import { MyPage } from 'ui/components/user/layouts/MyPage/MyPage';
import { UserStandard } from 'ui/components/user/layouts/UserStandard/UserStandard';
import { SupportChat } from 'ui/components/user/patterns/SupportChat/SupportChat';
import { CompanyCastings } from 'ui/pages/Company/Castings/CompanyCastings';
import CompanyChat from 'ui/pages/Company/Chat/CompanyChat';
import { CompanyContract } from 'ui/pages/Company/Contract/CompanyContract';
import { CompanyDashboard } from 'ui/pages/Company/Dashboard/CompanyDashboard';
import { CompanyEdit } from 'ui/pages/Company/Edit/CompanyEdit';
import { CompanyLogin } from 'ui/pages/Company/Login/CompanyLogin';
import { CompanyMeetRequest } from 'ui/pages/Company/MeetRequest/CompanyMeetRequest';
import CompanyDraftProjectComplete from 'ui/pages/Company/Project/CompanyDraftProjectComplete';
import CompanyProjectComplete from 'ui/pages/Company/Project/CompanyProjectComplete';
import CompanyProjectList from 'ui/pages/Company/Project/CompanyProjectList';
import CompanyProjectPrepare from 'ui/pages/Company/Project/CompanyProjectPrepare';
import CompanyShareDisableProjectComplete from 'ui/pages/Company/Project/CompanyShareDisableProjectComplete';
import SessionCreate from 'ui/pages/Company/Session/SessionCreate';
import SessionEdit from 'ui/pages/Company/Session/SessionEdit';
import { SendEmail } from 'ui/pages/Company/Register/SendEmail';
import CompanyUserSignUp from 'ui/pages/Company/Register/CompanyUserSignUp';
import CompanyUserRegisterProfile from 'ui/pages/Company/Register/CompanyUserRegisterProfile';
import RegisterCompanyProfile from 'ui/pages/Company/Register/RegisterCompanyProfile';
import CompanyProfileConfirm from 'ui/pages/Company/Register/CompanyProfileConfirm';
import { CompanySupport } from 'ui/pages/Company/Support/CompanySupport';
import { CompanyMeetRequestSurvey } from 'ui/pages/Company/Survey/CompanyMeetRequestSurvey';
import { IntroductionCompanySurvey } from 'ui/pages/Company/Survey/IntroductionCompanySurvey';
import CompanyUserEdit from 'ui/pages/Company/Users/CompanyUserEdit';
import CompanyUserList from 'ui/pages/Company/Users/CompanyUserList';
import { CompanyApplication } from 'ui/pages/CompanyApplication/CompanyApplication';
import { CompanyTerms } from 'ui/pages/CompanyApplication/CompanyTerms';
import { CommerceDisclosure } from 'ui/pages/Info/CommerceDisclosure';
import { Policy } from 'ui/pages/Info/Policy';
import { Teams } from 'ui/pages/Info/Teams';
import { NotFound } from 'ui/pages/NotFound/NotFound';
import { PublicCast } from 'ui/pages/PublicCast/PublicCast';
import { PublicProject } from 'ui/pages/PublicProject/PublicProject';
import { PublicRewards } from 'ui/pages/PublicRewards/PublicRewards';
import { Castings } from 'ui/pages/User/Castings/Castings';
import { Chat } from 'ui/pages/User/Chat/Chat';
import { EditFacebook } from 'ui/pages/User/Edit/Facebook';
import EmailChangeConfirm from 'ui/pages/User/EmailChangeConfirm';
import { GiftTickets } from 'ui/pages/User/GiftTickets/GiftTickets';
import { Gifts } from 'ui/pages/User/Gifts/Gifts';
import { Home } from 'ui/pages/User/Home/Home';
import { LoginFacebook } from 'ui/pages/User/Login/Facebook/Facebook';
import { Login } from 'ui/pages/User/Login/Login';
import { Meets } from 'ui/pages/User/Meets/Meets';
import PasswordReset from 'ui/pages/User/PasswordReset';
import { PointLog } from 'ui/pages/User/Point/PointLog/PointLog';
import { PointRanking } from 'ui/pages/User/Point/Ranking/Ranking';
import Questionnaires from 'ui/pages/User/Questionnaires/Questionnaires';
import { EmailVerify } from 'ui/pages/User/Register/EmailVerify';
import { Register } from 'ui/pages/User/Register/Register';
import { ShipInfo } from 'ui/pages/User/ShipInfo/ShipInfo';
import { IntroductionUserSurvey } from 'ui/pages/User/Survey/IntroductionUserSurvey';
import { UserMeetRequestSurvey } from 'ui/pages/User/Survey/UserMeetRequestSurvey';
import { Top } from 'ui/pages/User/Top/Top';
import { Withdrawal } from 'ui/pages/User/Withdrawal/Withdrawal';

import { AddCompanyBillingInfo } from 'ui/pages/Company/Billing/AddCompanyBillingInfo';
import { CompanyBillingInfo } from 'ui/pages/Company/Billing/CompanyBillingInfo';
import { CompanyBillingList } from 'ui/pages/Company/Billing/CompanyBillingList';
import CompanyProjectCheckoutSuccess from 'ui/pages/Company/Project/CompanyProjectCheckoutSuccess';
import { ClosingSoonProjects } from 'ui/pages/User/ClosingSoonProjects/ClosingSoonProjects';
import { MyIntroducedProjects } from 'ui/pages/User/MyIntroducedProjects/MyIntroducedProjects';
import { Project } from 'ui/pages/User/Project/Project';
import { ProjectReviews } from 'ui/pages/User/ProjectReviews/ProjectReviews';
import { RecommendProjects } from 'ui/pages/User/RecommendProjects/RecommendProjects';
import { SearchProjects } from 'ui/pages/User/SearchProjects/SearchProjects';
import { WantedProjects } from 'ui/pages/User/WantedProjects/WantedProjects';
import CompanyUserRegisterComplete from 'ui/pages/Company/Register/CompanyUserRegisterComplete';
import { Invite } from 'ui/pages/Company/Register/Invite';
import InvitedCompanyUserSignUp from './ui/pages/Company/Register/InvitedCompanyUserSignUp';

const router = createBrowserRouter([
  /** 公開ページ */
  {
    element: <PublicPageContextConfig />,
    children: [
      { path: '/projects/:projectId/', element: <PublicProject /> },
      {
        element: <PublicPage />,
        children: [
          { path: '/rewards/', element: <PublicRewards /> },
          { path: '/info/terms/', element: <Teams /> },
          { path: '/info/policy/', element: <Policy /> },
          { path: '/info/commerce-disclosure/', element: <CommerceDisclosure /> },
          { path: '/company-application/new/', element: <CompanyApplication /> },
          { path: '/company-application/terms/', element: <CompanyTerms /> },
        ],
      },
    ],
  },

  /** トップページ */
  { path: '/', element: <Top /> },

  /** ユーザー系 */
  {
    element: <UserContextConfig />,
    children: [
      /** 認証系 */
      { path: '/register/', element: <Register /> },
      { path: '/register/verify-email/', element: <EmailVerify /> },
      { path: '/login/', element: <Login /> },
      { path: '/login/facebook/', element: <LoginFacebook /> },
      { path: '/email-change-confirm/', element: <EmailChangeConfirm /> },
      { path: '/password-reset/', element: <PasswordReset /> },
      { path: '/cast/:castCode/', element: <PublicCast /> },
      {
        path: '/survey/user/introduction/answer/:surveyCode/',
        element: <IntroductionUserSurvey />,
      },
      {
        path: '/survey/meet-request/user/answer/:meetRequestId/',
        element: <UserMeetRequestSurvey />,
      },
      /** ログイン後 */
      {
        element: <UserStandard />,
        children: [
          { path: '/user/', element: <Home /> },
          { path: '/user/project/:projectId/', element: <Project /> },
          { path: '/user/kw/', element: <SearchProjects /> },
          { path: '/user/meets/', element: <Meets /> },
          { path: '/user/casting/', element: <Castings key="/user/casting/" /> },
          { path: '/user/casting/casted/', element: <Castings key="/user/casting/casted/" /> },
          { path: '/user/chat/rooms/', element: <Chat /> },
          { path: '/user/chat/rooms/:roomId/', element: <Chat /> },
          { path: '/user/chat/support/', element: <SupportChat /> },
          { path: '/user/gifts/', element: <Gifts /> },
          { path: '/user/gifts/delivery/', element: <Navigate to="/user/ship_info/" replace /> },
          { path: '/user/ship_info/', element: <ShipInfo /> },
          { path: '/user/gift_tickets/', element: <GiftTickets /> },
          { path: '/user/mypage/', element: <MyPage /> },
          { path: '/user/questionnaires/', element: <Questionnaires /> },
          { path: '/user/edit/', element: <Navigate to="/user/mypage/?profile=1" replace /> },
          { path: '/user/edit/facebook/', element: <EditFacebook /> },
          { path: '/user/point/ranking/', element: <PointRanking /> },
          { path: '/user/point/log/', element: <PointLog /> },
          { path: '/user/withdrawal/', element: <Withdrawal /> },
          { path: '/user/projects/recommend/', element: <RecommendProjects /> },
          { path: '/user/projects/wanted/', element: <WantedProjects /> },
          { path: '/user/projects/introduced/', element: <MyIntroducedProjects /> },
          { path: '/user/projects/reviews/', element: <ProjectReviews /> },
          { path: '/user/projects/closing/', element: <ClosingSoonProjects /> },
        ],
      },
    ],
  },

  /** 企業系 */
  {
    element: <CompanyContextConfig />,
    children: [
      /** 認証系 */
      { path: '/company/', element: <CompanyLogin /> },
      { path: '/company/password-reset/', element: <CompanyPasswordReset /> },
      { path: '/company/register/email/', element: <SendEmail /> },
      { path: '/company/register/auth/:url_id/', element: <CompanyUserSignUp /> },
      { path: '/company/main/users/invite/', element: <Invite /> },
      { path: '/company/invitation/auth/:url_id/', element: <InvitedCompanyUserSignUp /> },
      {
        path: '/survey/company/introduction/answer/:surveyCode/',
        element: <IntroductionCompanySurvey />,
      },
      {
        path: '/survey/meet-request/company/answer/:meetRequestId/',
        element: <CompanyMeetRequestSurvey />,
      },
      {
        path: '/company/register/user/profile/',
        element: <CompanyUserRegisterProfile />,
      },
      {
        path: '/company/register/company/profile/',
        element: <RegisterCompanyProfile />,
      },
      {
        path: '/company/register/confirm_profile/',
        element: <CompanyProfileConfirm />,
      },
      {
        path: '/company/registration/complete/',
        element: <CompanyUserRegisterComplete />,
      },

      /** ログイン後 */
      {
        element: <CompanyStandard />,
        children: [
          { path: '/company/main/', element: <CompanyDashboard /> },
          { path: '/company/main/users/', element: <CompanyUserList /> },
          { path: '/company/main/users/edit/:userId/', element: <CompanyUserEdit /> },
          { path: '/company/main/projects/', element: <CompanyProjectList /> },
          { path: '/company/main/projects/prepare/', element: <CompanyProjectPrepare /> },
          { path: '/company/main/projects/create/assets/:assetsId/', element: <SessionCreate /> }, // 生成アセット利用 セッションの新規作成
          { path: '/company/main/projects/create/', element: <SessionCreate /> }, // 空の状態でのセッションの新規作成
          { path: '/company/main/projects/create/:projectId/', element: <SessionCreate /> }, // セッションの複製
          { path: '/company/main/projects/edit/:projectId/', element: <SessionEdit /> },
          { path: '/company/main/draft/projects/edit/:draftId/', element: <SessionCreate /> },
          {
            path: '/company/main/projects/complete/:projectId/',
            element: <CompanyProjectComplete />,
          },
          {
            path: '/company/main/projects/draft/complete/',
            element: <CompanyDraftProjectComplete />,
          },
          {
            path: '/company/main/projects/share-disable/complete/:projectId/',
            element: <CompanyShareDisableProjectComplete />,
          },
          { path: '/company/main/chat/support/', element: <CompanySupport /> },
          { path: '/company/main/chat/support/:roomId/', element: <CompanySupport /> },
          { path: '/company/main/chat/room/:roomId/', element: <CompanyChat /> },
          { path: '/company/main/chat/:roomId/', element: <CompanyChat /> },
          { path: '/company/main/castings/', element: <CompanyCastings /> },
          { path: '/company/main/edit/', element: <CompanyEdit /> },
          { path: '/company/main/contract/', element: <CompanyContract /> },
          { path: '/company/main/meet/', element: <CompanyMeetRequest /> },
          { path: '/company/billing-info/', element: <CompanyBillingInfo /> },
          { path: '/company/billing-info/new', element: <AddCompanyBillingInfo /> },
          {
            path: '/company/projects/:projectId/checkout/success',
            element: <CompanyProjectCheckoutSuccess />,
          },
          { path: '/company/payments', element: <CompanyBillingList /> },
        ],
      },
    ],
  },

  { path: '*', element: <Navigate to="/not_found/" replace /> },
  { path: '/not_found/', element: <NotFound /> },
]);

const RouterConfig: FC = () => <RouterProvider router={router} />;

export default RouterConfig;
