import { useContext, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { authContext } from 'application/contexts/useAuth';
import { LoginLayout } from 'ui/components/user/layouts/Login/Login';

export const Login: React.FC = () => {
  const { search } = useLocation();
  const navigate = useNavigate();
  const { urlAfterLogin, checkToken, restoreCastInfo } = useContext(authContext);

  useEffect(() => {
    const param = new URLSearchParams(search);
    const castCode = param.get('cast_code');
    if (castCode) {
      restoreCastInfo(castCode);
      return; // キャストは自動ログインさせない
    }
    // 有効なトークンがあればリダイレクト
    const redirect = async () => {
      if (await checkToken(false)) {
        navigate(urlAfterLogin);
      }
    };
    redirect();
  }, []);

  return <LoginLayout />;
};
