import { useCallback, useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import yup from 'utils/yup';
import { truncate } from 'utils/string';
import { CompanyMeetRequestRepository } from 'interfaceAdapter/repositories/CompanyMeetRequest';
import { diContainerContext } from 'application/contexts/useDiContainer';
import { companyAppContext } from 'application/contexts/useCompanyApp';
import { useModal } from 'ui/components/company/screens/modals/useModal';
import { CompanyProjectQuery } from 'interfaceAdapter/queries/CompanyProject';

const schema = yup
  .object({
    topic: yup.string().default('').required(),
    attender: yup.string().default('').required(),
    project_id: yup
      .number()
      .default(-1)
      .test('emptyProjectId', 'この項目は選択必須です', (value) => value !== -1)
      .required(),
  })
  .required();

type MeetRequestForm = yup.InferType<typeof schema>;

export const useSendMeetRequest = () => {
  const diContainer = useContext(diContainerContext);
  const companyMeetRequestRepository = diContainer.resolve(CompanyMeetRequestRepository);
  const companyProjectQuery = diContainer.resolve(CompanyProjectQuery);
  const { meetRequestModalProps, setMeetRequestModalProps } = useContext(companyAppContext);
  const { control, handleSubmit, formState, setValue, reset } = useForm<MeetRequestForm>({
    resolver: yupResolver(schema),
    mode: 'onChange',
    defaultValues: schema.cast({}),
  });
  const { openModal, closeModal, createModal } = useModal();
  const [projects, setProjects] = useState<{ value: string | number; label: string }[]>([]);
  const [requesting, setRequesting] = useState(false);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const fetchProjects = async () => {
    const projects = await companyProjectQuery.getOpen();
    const formatted = projects.map((project) => ({
      value: project.id as number,
      label: truncate(project.title, 31),
    }));
    setProjects(formatted);
  };

  const close = useCallback(() => {
    reset();
    setMeetRequestModalProps(null);
  }, []);

  const save = handleSubmit(async (data) => {
    if (!meetRequestModalProps?.userId) {
      return;
    }
    setRequesting(true);
    try {
      await companyMeetRequestRepository.sendMeetRequest(
        meetRequestModalProps.userId,
        data.topic,
        data.attender,
        data.project_id,
      );
      setShowSnackbar(true);
      close();
    } finally {
      setRequesting(false);
    }
  });

  useEffect(() => {
    if (!meetRequestModalProps?.project) return;

    setValue('project_id', meetRequestModalProps.project.id);
    setProjects([
      {
        value: meetRequestModalProps.project.id,
        label: truncate(meetRequestModalProps.project.title, 31),
      },
    ]);
  }, [meetRequestModalProps?.project]);

  useEffect(() => {
    if (meetRequestModalProps !== null) {
      fetchProjects();
      openModal();
    } else {
      closeModal();
    }
  }, [meetRequestModalProps]);

  return {
    control,
    formState,
    projects,
    requesting,
    createModal,
    showSnackbar,
    setShowSnackbar,
    close,
    save,
  };
};
