import { useEffect, useState, useContext } from 'react';
import { IProject } from 'domain/entities/Project/Project';
import { ProjectWithApplied } from 'domain/valueObjects/application/ProjectWithApplied';
import { userAppliedProjectIdsContext } from 'application/contexts/useUserAppliedProjectIds';
import { ProjectQuery } from 'interfaceAdapter/queries/Project';
import { diContainerContext } from 'application/contexts/useDiContainer';
import { ApplicationFactory } from 'domain/valueObjects/factories/Application';

export const useRecommendedProject = () => {
  const [recommendedProjects, setRecommendedProjects] = useState<ProjectWithApplied[]>([]);
  const [projectItemLength, setProjectItemLength] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);
  const { appliedProjectIds } = useContext(userAppliedProjectIdsContext);
  const diContainer = useContext(diContainerContext);
  const projectQuery = diContainer.resolve(ProjectQuery);

  const fetchProjects = async () => {
    setLoading(true);
    const res = await projectQuery.fetchBulk({
      word: null,
      offset: 0,
      filterType: 'featured',
    });
    const projects = res.projects;
    const projectWithApplied = projects.map(
      (project: IProject): ProjectWithApplied =>
        ApplicationFactory.buildProjectWithApplied({ project, appliedProjectIds }),
    );

    setProjectItemLength(projectWithApplied.length);
    setRecommendedProjects(
      projectWithApplied.length <= 10
        ? [...projectWithApplied, ...projectWithApplied]
        : projectWithApplied,
    );
    setLoading(false);
  };

  useEffect(() => {
    fetchProjects();
  }, []);

  return {
    loading,
    setLoading,
    recommendedProjects,
    projectItemLength,
  };
};
