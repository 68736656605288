import { useFetch } from './useFetch';
import { useApplicationForm } from './useApplicationForm';

export const usePresenter = () => {
  const { applicationFormContent } = useFetch();
  const { control, errors, requesting, onSubmit, isCompleted } = useApplicationForm({
    applicationFormContent,
  });

  return {
    control,
    errors,
    requesting,
    onSubmit,
    isCompleted,
    applicationFormContent,
  };
};
