import styled from '@emotion/styled';
import { FC } from 'react';
import { mq } from 'utils/responsive';

const YellowWrapper = styled.div`
  background-color: var(--spr-primary);
  position: relative;
  margin-bottom: -60px;
  min-height: 100vh;
  @supports (height: 100svh) {
    min-height: 100svh;
  }
  padding: 166px 0 120px;
  text-align: center;

  ${mq('NARROW')} {
    padding: 18vw 0 16vw;
    margin-bottom: -13.3vw;
    @supports (padding: 18svw) {
      padding: 18svw 0 16svw;
      margin-bottom: -13.3svw;
    }
  }
`;

const Title = styled.h1`
  font-size: 38px;
  margin-bottom: 60px;

  ${mq('NARROW')} {
    font-size: 6.4vw;
    margin-bottom: 6.66vw;
    line-height: 1.2em;
    @supports (font-size: 6.4svw) {
      font-size: 6.4svw;
      margin-bottom: 6.66svw;
    }
  }
`;

const Content = styled.div`
  margin: 0 auto;
  background-color: var(--spr-white);
  max-width: 1024px;
  padding: 80px;
  border-radius: 20px;
  text-align: left;

  ${mq('NARROW')} {
    width: 86.66vw;
    padding: 9.33vw 6.66vw;
    border-radius: 5.33vw;
    @supports (width: 100svw) {
      width: 86.66svw;
      padding: 9.33svw 6.66svw;
      border-radius: 5.33svw;
    }
  }

  li {
    line-height: 2;
    font-size: 16px;

    ${mq('NARROW')} {
      line-height: 1.7;
      font-size: 3.73vw;
      @supports (font-size: 3.73svw) {
        font-size: 3.73svw;
      }
    }
  }
`;

const PaddingTop = styled.div`
  padding-top: 50px;
`;

const SubTitle = styled.h2`
  margin: 0;
  font-weight: bold;
  line-height: 2;
  font-size: 16px;

  ${mq('NARROW')} {
    line-height: 1.7;
    font-size: 4.53vw;
    @supports (font-size: 4.53svw) {
      font-size: 4.53svw;
    }
  }
`;

const Text = styled.p`
  margin: 0;
  display: block;
  line-height: 2;
  font-size: 16px;

  ${mq('NARROW')} {
    line-height: 1.7;
    font-size: 3.73vw;
    @supports (font-size: 3.73svw) {
      font-size: 3.73svw;
    }
  }
`;

export const CommerceDisclosure: FC = () => {
  return (
    <YellowWrapper>
      <Title>特定商取引法に基づく表記</Title>
      <Content>
        <SubTitle>販売事業者名</SubTitle>
        <Text>Spready株式会社</Text>
        <SubTitle>販売事業者所在地</SubTitle>
        <Text>東京都渋谷区恵比寿4丁目20番3号&nbsp;恵比寿ガーデンプレイスタワー19F</Text>
        <SubTitle>運営統括責任者</SubTitle>
        <Text>佐古雅亮</Text>
        <SubTitle>連絡先／ホームページ</SubTitle>
        <Text>https://spready.co.jp/</Text>
        <SubTitle>連絡先／電子メール</SubTitle>
        <Text>info@spready.co.jp</Text>
        <SubTitle>連絡先／電話番号</SubTitle>
        <Text>03-6897-3583</Text>
        <SubTitle>販売価格帯</SubTitle>
        <Text>サービスごとに販売価格（消費税等含む）を設定し、該当ページで表示します。</Text>
        <SubTitle>商品等の引き渡し時期</SubTitle>
        <Text>即時</Text>
        <SubTitle>発送方法</SubTitle>
        <Text>オンライン</Text>
        <SubTitle>代金の支払時期および方法</SubTitle>
        <Text>サービスごとに支払時期および方法を指定し、該当ページで表示します。</Text>
        <SubTitle>追加手数料等の追加料金</SubTitle>
        <Text>なし</Text>
        <SubTitle>交換および返品（返金ポリシー）</SubTitle>
        <Text>
          解約はサービスごとに当社指定の手続きにより可能。ただし、既に支払われた分に対する返金は不可。
        </Text>
        <SubTitle>不良品の取扱条件</SubTitle>
        <Text>現状有姿による利用となります</Text>
        <SubTitle>受け付け可能な決済手段</SubTitle>
        <Text>クレジットカードまたは国内の銀行振込</Text>
        <SubTitle>決済期間</SubTitle>
        <Text>
          クレジットカード決済の場合はただちに処理されますが、国内の銀行振込の場合は当月の月末締め翌月末払い
        </Text>
        <PaddingTop />
      </Content>
    </YellowWrapper>
  );
};
