import { FC } from 'react';
import styled from '@emotion/styled';
import { mq } from 'utils/responsive';
import { BackButton } from 'ui/components/publicPages/elements/BackButton/BackButton';
import { FormLabel } from 'ui/components/publicPages/elements/FormLabel/FormLabel';
import { Controller } from 'react-hook-form';
import { Input } from 'ui/components/publicPages/elements/Input/Input';
import { Checkbox } from 'ui/components/publicPages/elements/Checkbox/Checkbox';
import { CustomLink } from 'ui/components/publicPages/elements/CustomLink/CustomLink';
import { Button } from 'ui/components/publicPages/elements/Button/Button';
import { ValidationError } from 'ui/components/publicPages/elements/ValidationError/ValidationError';
import { usePresenter } from './usePresenter';

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  background-color: var(--spr-primary);
`;

const Content = styled.div`
  max-width: 650px;
  margin: 0 auto;
  padding-block: 185px 80px;
`;

const Padding = styled.div`
  max-width: 1024px;
  margin: 0 auto;
  padding: 0 20px;
  ${mq('NARROW')} {
    padding: 0 20px;
  }
  ${mq('SP')} {
    padding: 0 20px;
  }
`;

const LeadTitle = styled.div`
  text-align: center;
  font-weight: bold;
  font-size: var(--font-size--x-large);
  margin-bottom: 30px;
`;

const Title = styled.h3`
  font-size: var(--font-size--xxx-large);
  font-weight: var(--font-weight--bolder);
  position: relative;
  text-align: center;
  line-height: 1.5;
  margin: 0 auto 50px;
  padding-bottom: 30px;

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    display: block;
    background: var(--spr-black);
    height: 4px;
    width: 91px;
    border-radius: 2px;
  }
`;

const ThanksText = styled.div`
  line-height: 1.5em;
  font-size: var(--font-size--medium);
`;

const FormSection = styled.div`
  background-color: var(--spr-white);
  line-height: 1.5em;
  font-size: var(--font-size--medium);
  padding: 40px;
  border-radius: 40px;
`;

const Group = styled.div`
  margin-bottom: 60px;
  text-align: left;

  > div:first-of-type {
    margin-bottom: 18px;
  }
`;

const Names = styled.div`
  display: flex;
  gap: 5px;
  justify-content: space-between;
  width: 100%;
  > * {
    max-width: 50%;
    flex: 1;
  }
  ${mq('SP')} {
    flex-direction: column;
    gap: 24px;
    > * {
      max-width: 100%;
    }
  }
`;

const Other = styled.div`
  width: 100%;
  > * {
    width: 100%;
  }
`;

const Small = styled.span`
  display: inline-block;
  margin-top: 8px;
  font-size: 11px;
  line-height: 18px;
  color: #0009;
`;

const LinkWrapper = styled.div`
  display: block;
  margin-top: 16px;
  a {
    font-size: var(--font-size--small);
    text-decoration: underline;
  }
`;

const Block = styled.div`
  display: block;
`;

const Error = styled.span`
  white-space: nowrap;
`;

const ButtonWrapper = styled.div`
  text-align: center;
`;

const Margin = styled.div`
  margin-top: 40px;
`;

const PlainText = styled.div`
  white-space: pre-wrap;
`;

export const CompanyApplicationsContent: FC = () => {
  const { control, errors, requesting, onSubmit, isCompleted, applicationFormContent } =
    usePresenter();

  return (
    <>
      <Wrapper>
        <Content>
          <Padding>
            <LeadTitle>この度は、Spreadyご利用のご検討誠にありがとうございます</LeadTitle>
            <Title>アカウント発行手続きフォーム</Title>
            {isCompleted ? (
              <ThanksText>
                Spreadyへのお申し込み、誠にありがとうございました。
                <br />
                皆さまの事業や取り組みを強く支援するサービスとなりますよう、運営一同、尽力いたします。
                <br />
                改めまして、どうぞよろしくお願いします。
                <br />
                <br />
                アカウント情報をメールにて送付いたしました。
                <br />
                ご確認よろしくお願いいたします。
              </ThanksText>
            ) : (
              <FormSection>
                <Group>
                  <FormLabel title="御社名" isRequired />
                  <Other>
                    <Controller
                      name="company_name"
                      control={control}
                      render={({ field }) => (
                        <Input {...field} placeholder="" error={errors.company_name?.message} />
                      )}
                    />
                  </Other>
                  <Small>変更がある場合にご入力ください</Small>
                </Group>

                <Group>
                  <FormLabel title="御社郵便番号" isRequired />
                  <Other>
                    <Controller
                      name="company_zipcode"
                      control={control}
                      render={({ field }) => (
                        <Input
                          {...field}
                          placeholder="111-2222"
                          error={errors.company_zipcode?.message}
                        />
                      )}
                    />
                  </Other>
                  <Small>請求書のご送付先になります</Small>
                </Group>

                <Group>
                  <FormLabel title="御社住所" isRequired />
                  <Other>
                    <Controller
                      name="company_address"
                      control={control}
                      render={({ field }) => (
                        <Input
                          {...field}
                          placeholder="東京都渋谷区1-1-1"
                          error={errors.company_address?.message}
                        />
                      )}
                    />
                  </Other>
                  <Small>請求書のご送付先になります</Small>
                </Group>

                <Group>
                  <FormLabel title="お名前" isRequired />
                  <Names>
                    <Controller
                      name="charge_last_name"
                      control={control}
                      render={({ field }) => (
                        <Input
                          {...field}
                          placeholder="姓"
                          error={errors.charge_last_name?.message}
                        />
                      )}
                    />
                    <Controller
                      name="charge_first_name"
                      control={control}
                      render={({ field }) => (
                        <Input
                          {...field}
                          placeholder="名"
                          error={errors.charge_first_name?.message}
                        />
                      )}
                    />
                  </Names>
                </Group>

                <Group>
                  <FormLabel title="メールアドレス" isRequired />
                  <Other>
                    <Controller
                      name="charge_email"
                      control={control}
                      render={({ field }) => (
                        <Input
                          {...field}
                          placeholder="メールアドレス"
                          error={errors.charge_email?.message}
                        />
                      )}
                    />
                  </Other>
                  <Small>最初のアカウントの発行が行われます</Small>
                </Group>

                {applicationFormContent.description && (
                  <Group>
                    <FormLabel title="特記事項" isRequired />
                    <Other>
                      <PlainText>{applicationFormContent.description}</PlainText>
                    </Other>
                  </Group>
                )}

                <Group>
                  <FormLabel title="利用企業ご紹介サイトへの企業ロゴの掲載許可" />
                  <Controller
                    name="logo_use_enable"
                    control={control}
                    render={({ field }) => (
                      <Block>
                        <Checkbox
                          id="logo_use_enable"
                          checked={field.value}
                          onChange={() => field.onChange(!field.value)}
                        >
                          使用可
                        </Checkbox>
                      </Block>
                    )}
                  />
                </Group>

                <Group>
                  <FormLabel title="利用規約に同意" isRequired />
                  <Controller
                    name="accept_terms"
                    control={control}
                    render={({ field }) => (
                      <Block>
                        <Checkbox
                          id="accept_terms"
                          checked={field.value}
                          onChange={() => field.onChange(!field.value)}
                        >
                          利用規約に同意
                        </Checkbox>
                      </Block>
                    )}
                  />
                  <Error>
                    <ValidationError message={errors.accept_terms?.message} />
                  </Error>
                  <LinkWrapper>
                    <CustomLink
                      url="/company-application/terms/"
                      text="利用規約はこちら"
                      target="_blank"
                    />
                  </LinkWrapper>
                </Group>

                <Group>
                  <FormLabel title="プライバシーポリシーに同意" isRequired />
                  <Controller
                    name="accept_policy"
                    control={control}
                    render={({ field }) => (
                      <Block>
                        <Checkbox
                          id="accept_policy"
                          checked={field.value}
                          onChange={() => field.onChange(!field.value)}
                        >
                          プライバシーポリシーに同意
                        </Checkbox>
                      </Block>
                    )}
                  />
                  <Error>
                    <ValidationError message={errors.accept_policy?.message} />
                  </Error>
                  <LinkWrapper>
                    <CustomLink
                      url="/info/policy/"
                      text="プライバシーポリシーはこちら"
                      target="_blank"
                    />
                  </LinkWrapper>
                </Group>

                <ButtonWrapper>
                  <Button button_type="dark" width="50%" disabled={requesting} onClick={onSubmit}>
                    利用規約に同意の上申し込む
                  </Button>
                </ButtonWrapper>
              </FormSection>
            )}
          </Padding>
        </Content>
      </Wrapper>
      <Margin>
        <BackButton />
      </Margin>
    </>
  );
};
