import { FC, useEffect, useRef, useState } from 'react';
import styled from '@emotion/styled';
import { useNavigate } from 'react-router-dom';
import { useTopProjectList } from 'ui/pages/User/Top/useTopProjectList';
import { css, keyframes } from '@emotion/react';
import { LpHeader } from 'ui/components/publicPages/panes/Header/LpHeader';
import { LpFooter } from 'ui/components/publicPages/panes/Footer/LpFooter';
import { TopProjectList } from 'ui/components/publicPages/patterns/TopProjectList/TopProjectList';
import { TopCheckSession } from 'ui/components/publicPages/patterns/TopCheckSession/TopCheckSession';
import { TopSessionFlow } from 'ui/components/publicPages/patterns/TopSessionFlow/TopSessionFlow';
import { TopEnjoyPoints } from 'ui/components/publicPages/patterns/TopEnjoyPoints/TopEnjoyPoints';
import { TopClients } from 'ui/components/publicPages/patterns/TopClients/TopClients';
import { TopQa } from 'ui/components/publicPages/patterns/TopQa/TopQa';
import { TopSplash } from 'ui/components/publicPages/patterns/TopSplash/TopSplash';
import { LinkWithChild } from 'ui/components/publicPages/elements/LinkWithChild/LinkWithChild';
import { FooterClientItem } from 'ui/components/publicPages/patterns/FooterClientItem/FooterClientItem';
import BgImg from 'ui/images/user-lp/bg_mv.png';
import BgImgSp from 'ui/images/user-lp/bg_mv_sp.png';
import MvBaseImg from 'ui/images/user-lp/mv_img_base.png';
import MvImgGuest01 from 'ui/images/user-lp/mv_img_guest_01.png';
import MvImgPanel01 from 'ui/images/user-lp/mv_img_panel_01.png';
import MvImgGuest02 from 'ui/images/user-lp/mv_img_guest_02.png';
import MvImgPanel02 from 'ui/images/user-lp/mv_img_panel_02.png';
import MvImgGuest03 from 'ui/images/user-lp/mv_img_guest_02.png';
import MvImgPanel03 from 'ui/images/user-lp/mv_img_panel_02.png';
import MvImgGuest04 from 'ui/images/user-lp/mv_img_guest_04.png';
import MvImgPanel04 from 'ui/images/user-lp/mv_img_panel_04.png';
import MvImgGuest05 from 'ui/images/user-lp/mv_img_guest_05.png';
import MvImgPanel05 from 'ui/images/user-lp/mv_img_panel_05.png';
import BgServicePc from 'ui/images/user-lp/bg_service_pc.png';
import BgServiceSp from 'ui/images/user-lp/bg_service_sp.png';
import ButtonImg from 'ui/images/user-lp/icon_button.svg';
import ScrollImg from 'ui/images/user-lp/text_scroll.svg';
import BgStatementImgPc from 'ui/images/user-lp/bg_statement_pc.png';
import BgStatementImgSp from 'ui/images/user-lp/img_statement_sp.png';
import IconBlankImg from 'ui/images/user-lp/icon_blank.svg';
import LogoNoteImg from 'ui/images/user-lp/logo_note.svg';
import IconCloseImg from 'ui/images/user-lp/icon_close.svg';
import IconCloseWhImg from 'ui/images/user-lp/icon_close_wh.svg';
import { mq } from 'utils/responsive';
import { SplashVisibilityRepository } from 'interfaceAdapter/repositories/SplashVisibility';
import { ISplashVisibilityRepository } from 'application/repositorySchemas/ISplashVisibilityRepository';
import { CookieNotificationRepository } from 'interfaceAdapter/repositories/CookieNotification';
import { ICookieNotificationRepository } from 'application/repositorySchemas/ICookieNotificationRepository';
import { CustomLink } from 'ui/components/publicPages/elements/CustomLink/CustomLink';

const OverFlowY = styled.div`
  overflow-y: auto;
  height: 100vh;
  @supports (height: 100svh) {
    height: 100svh;
  }
`;

const TopBgContainer = styled.div`
  background-image: url(${BgImg});
  background-size: cover;
  background-position: center;
  position: relative;
  height: 100vh;
  @supports (height: 100svh) {
    height: 100svh;
  }
  min-height: 660px;
  padding: 100px 0 0 0;

  ${mq('NARROW')} {
    background-image: url(${BgImgSp});
    height: auto;
    padding: 26.6vw 0 8.5vw;

    @supports (width: 100svw) {
      padding: 26.6svw 0 8.5svw;
    }
  }
`;

const TopImgContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-100px) translateY(-218px);
  width: 657px;
  height: 464px;

  & > img:nth-of-type(1) {
    position: relative;
    z-index: 101;
    height: auto;
  }

  ${mq('NARROW')} {
    position: relative;
    width: 100%;
    height: auto;
    overflow: hidden;
    top: 0;
    left: 0;
    transform: none;
  }
`;

const guestKeyframes = keyframes`
  0%{
    opacity: 0;
    transform: translateX(20px);
  }
  20%{
    opacity: 0;
    transform: translateX(20px);
  }
  40%{
    opacity: 1;
    transform: translateX(0px);
  }
  95%{
    opacity: 1;
  }
  100%{
    opacity: 0;
  }
`;

const panelKeyframes = keyframes`
  0% {
    opacity: 0;
  }
  20% {
    opacity: 1;
  }
  95% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
`;

const scroll = keyframes`
  0% {
      transform: scaleY(0);
      transform-origin: top;
  }
  10% {
      transform: scaleY(0);
      transform-origin: top;
  }
  25% {
      transform: scaleY(1);
      transform-origin: top;
  }
  85% {
      transform: scaleY(1);
      transform-origin: bottom;
  }
  100% {
      transform: scaleY(0);
      transform-origin: bottom;
  }
`;

const TopImgBox = styled.div<{ isActive: boolean; index: number }>`
  position: absolute;
  top: 0;
  left: 0;

  img {
    width: 657px;
    height: 464px;
    position: absolute;
    opacity: 0;
  }

  & > img:nth-of-type(1) {
    z-index: 102;
  }

  & > img:nth-of-type(2) {
    z-index: 100;
  }

  ${({ isActive }) =>
    isActive &&
    css`
      & > img:nth-of-type(1) {
        animation: ${guestKeyframes} 4s forwards;
      }
      & > img:nth-of-type(2) {
        animation: ${panelKeyframes} 4s forwards;
      }
    `}

  ${mq('NARROW')} {
    img {
      width: 96vw;
      height: 72.9vw;

      @supports (width: 100svw) {
        width: 96svw;
        height: 72.9svw;
      }
    }
  }
`;

const TopTextContainer = styled.div`
  position: absolute;
  width: 1024px;
  height: 270px;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  margin: auto;

  p {
    width: 390px;
    font-size: 36px;
    margin-bottom: 20px;
    line-height: 1.66;
    font-weight: bold;
  }

  ${mq('NARROW')} {
    position: relative;
    padding: 0 5.3svw;
    margin-bottom: 5.3svw;
    letter-spacing: -0.05em;
    width: 100%;
    height: auto;

    p {
      width: 100%;
      margin-top: 0;
      font-size: 7.2vw;
      @supports (width: 100svw) {
        font-size: 7.2svw;
      }
    }
  }
`;

const RegisterButton = styled.div`
  display: inline-block;
  border-color: rgb(0, 0, 0);
  border-style: solid;
  padding: 10px 55px 10px 30px;
  font-weight: bold;
  background-image: url(${ButtonImg});
  background-size: 24px;
  background-position: right 20px center;
  background-repeat: no-repeat;
  transition: transform 0.1s, box-shadow 0.1s;
  background-color: #d52400;
  color: var(--spr-white);
  font-size: 20px;
  padding-top: 20px;
  padding-bottom: 20px;
  border-radius: 40px;
  border-width: 4px;
  box-shadow: 0px 4px 0px 0px rgb(0, 0, 0);
  width: 368px;
  text-align: center;

  ${mq('NARROW')} {
    width: 100%;
    border-width: 1.06vw;
    border-radius: 10.6vw;
    box-shadow: 0px 1.06vw 0px 0px rgb(0, 0, 0);
    background-size: 6.13vw;
    background-position: right 4vw center;
    padding: 5.86vw 8vw 5.86vw 0;
    font-size: 4.26vw;

    @supports (width: 100svw) {
      border-width: 1.06svw;
      border-radius: 10.6svw;
      box-shadow: 0px 1.06svw 0px 0px rgb(0, 0, 0);
      background-size: 6.13svw;
      background-position: right 4svw center;
      padding: 5.86svw 8svw 5.86svw 0;
      font-size: 4.26svw;
    }
  }
`;

const ScrollIconContainer = styled.div`
  position: absolute;
  bottom: 65px;
  left: 50%;
  transform: translateX(-510px);
  height: 66px;
  padding-left: 10px;

  img {
    width: 9px;
    height: auto;
  }
`;

const ScrollIconLine = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 2px;
  height: 100%;
  background: var(--spr-black);
  animation: ${scroll} 2.5s forwards;
  animation-timing-function: cubic-bezier(1, 0, 0, 1);
  animation-iteration-count: infinite;
  transform: scale(0);
  transform-origin: top;
`;

const SessionTotal = styled.div`
  margin: 60px 0 100px;
  text-align: center;

  ${mq('NARROW')} {
    margin: 10.66vw 0 18.66vw;
    @supports (width: 100svw) {
      margin: 10.66svw 0 18.66svw;
    }
  }
`;

const SessionTotalInner = styled.div`
  padding-bottom: 10px;
  border-bottom: 4px solid var(--spr-black);
  display: inline-flex;
  align-items: center;

  ${mq('NARROW')} {
    padding-bottom: 2.66vw;
    border-bottom: 1.066vw solid var(--spr-black);

    @supports (width: 100svw) {
      padding-bottom: 2.66svw;
      border-bottom: 1.066svw solid var(--spr-black);
    }
  }
`;

const SessionTotalText = styled.p`
  font-size: 26px;
  margin: 0 38px 0 0;
  text-align: center;
  font-weight: bold;
  line-height: 1.3;

  span {
    font-size: 16px;
  }

  ${mq('NARROW')} {
    font-size: 4.53vw;
    margin-right: 7.466vw;

    @supports (width: 100svw) {
      font-size: 4.53svw;
      margin-right: 7.466svw;
    }

    span {
      font-size: 16px;
      font-size: 2.93vw;
      @supports (width: 100svw) {
        font-size: 2.93svw;
      }
    }
  }
`;

const SessionTotalCount = styled.p`
  font-size: 48px;
  font-weight: bold;
  line-height: 1.3;
  margin: 0;

  ${mq('NARROW')} {
    font-size: 12.8vw;
    @supports (width: 100svw) {
      font-size: 12.8svw;
    }
  }
`;

const ServiceContainer = styled.div`
  background-image: url(${BgServicePc});
  background-position: center 80px;
  padding: 100px 0 0;
  background-color: var(--spr-primary);
  background-repeat: no-repeat;

  ${mq('NARROW')} {
    background-image: url(${BgServiceSp});
    background-position: center 32vw;
    padding: 13.33vw 0 0;

    @supports (width: 100svw) {
      background-position: center 32svw;
      padding: 13.33svw 0 0;
    }
  }
`;

const Break = styled.br`
  display: none;
  ${mq('NARROW')} {
    display: block;
  }
`;

const StatementContainer = styled.div`
  margin-bottom: 100px;
  background-image: url(${BgStatementImgPc});
  background-position: center;
  background-repeat: no-repeat;
  background-size: 1920px;
  padding: 120px 0 100px;
  background-color: var(--spr-primary);
  image-rendering: -webkit-optimize-contrast;

  ${mq('NARROW')} {
    margin-bottom: 17.2vw;
    background-image: none;
    padding: 18.66svw 0 13.33vw;

    @supports (width: 100svw) {
      margin-bottom: 17.2svw;
      padding: 18.66svw 0 13.33svw;
    }
  }
`;

const StatementContent = styled.div`
  width: 1024px;
  margin: 0 auto;

  ${mq('NARROW')} {
    width: 76vw;
    @supports (width: 100svw) {
      width: 76svw;
    }
  }
`;

const StatementTitle = styled.h2`
  margin: 0 0 40px;
  font-size: 44px;
  line-height: 1.7;

  ${mq('NARROW')} {
    width: 72.66vw;
    margin: 0 auto 10.66vw;
    font-size: 8.8vw;

    @supports (width: 100svw) {
      width: 72.66svw;
      margin: 0 auto 10.66svw;
      font-size: 8.8svw;
    }
  }
`;

const StatementText = styled.p`
  font-size: 16px;
  margin-bottom: 36px;
  font-weight: bold;
  line-height: 2;

  ${mq('NARROW')} {
    width: 72.66vw;
    font-size: 3.733vw;
    margin: 0 auto 9.33vw;

    @supports (width: 100svw) {
      width: 72.66svw;
      font-size: 3.733svw;
      margin: 0 auto 9.33svw;
    }
  }
`;

const BreakPc = styled.br`
  display: block;
  ${mq('NARROW')} {
    display: none;
  }
`;

const OperatingCompany = styled.div`
  padding: 20px 40px;
  width: 444px;
  border-radius: 20px;
  display: flex;
  align-items: center;
  background-color: var(--spr-white);

  p {
    margin: 0;
  }

  span {
    font-size: 16px;
    font-weight: bold;
    line-height: 2;
  }

  ${mq('NARROW')} {
    width: 100%;
    padding: 4vw 5.33vw;
    border-radius: 5.33vw;

    @supports (width: 100svw) {
      padding: 4svw 5.33svw;
      border-radius: 5.33svw;
    }

    span {
      font-size: 3.2vw;
      @supports (width: 100svw) {
        font-size: 3.2svw;
      }
    }
  }
`;

const OperatingCompanyLeft = styled.div`
  font-size: 14px;
  margin-right: 29px;
  padding: 5px 0;

  ${mq('NARROW')} {
    font-size: 2.66vw;
    margin-right: 2.66vw;
    padding: 4vw 0;

    @supports (width: 100svw) {
      font-size: 2.66svw;
      margin-right: 2.66svw;
      padding: 4svw 0;
    }
  }
`;

const OperatingCompanyRight = styled.div`
  padding: 5px 0 5px 29px;
  border-left: 2px solid var(--gray-light-2);

  a {
    font-size: 16px;
    margin-bottom: 10px;
    font-weight: bold;
  }

  img[src='${LogoNoteImg}'] {
    width: 70px;
    height: 17px;
    display: inline-block;
  }

  img[src='${IconBlankImg}'] {
    width: 70px;
    height: 17px;
    margin-left: 5px;
    display: inline-block;
  }

  ${mq('NARROW')} {
    padding: 4vw 0 4vw 2.66vw;
    border-left: 0.53vw solid var(--gray-light-2);

    @supports (width: 100svw) {
      padding: 4svw 0 4svw 2.66svw;
      border-left: 0.53svw solid var(--gray-light-2);
    }

    a {
      font-size: 2.93vw;
      margin-bottom: 2.66vw;

      @supports (width: 100svw) {
        font-size: 2.93svw;
        margin-bottom: 2.66svw;
      }
    }

    img[src='${LogoNoteImg}'] {
      width: 16.53vw;
      height: 4vw;

      @supports (width: 100svw) {
        width: 16.53svw;
        height: 4svw;
      }
    }

    img[src='${IconBlankImg}'] {
      width: 3.73vw;
      height: 3.73vw;

      @supports (width: 100svw) {
        width: 3.73svw;
        height: 3.73svw;
      }
    }
  }
`;

const BgStatement = styled.div`
  display: none;
  ${mq('NARROW')} {
    display: block;
  }
`;

const CloseIcon = styled.div`
  position: absolute;
  top: 39px;
  left: 50%;
  margin-left: 440px;
  cursor: pointer;
  z-index: var(--z-lp-splash);

  img {
    width: 20px;
    height: 20px;
  }

  ${mq('NARROW')} {
    top: 4vw;
    right: 5.6vw;
    left: auto;

    @supports (width: 100svw) {
      top: 4svw;
      right: 5.6svw;
    }
  }
`;

const CookiePolicy = styled.div`
  bottom: 50px;
  border-radius: 20px;
  width: 1024px;
  padding: 25px 80px;
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  background-color: rgba(0, 0, 0, 0.85);
  color: var(--spr-white);
  z-index: var(--z-lp-cookie);

  a {
    display: inline-block;
  }

  p {
    font-weight: bold;
    margin: 0;
    display: inline-block;
  }

  p:first-of-type {
    font-size: 16px;
    margin-bottom: 18px;
  }

  p:last-of-type {
    font-size: 14px;
  }

  ${mq('NARROW')} {
    bottom: 4vw;
    border-radius: 5.33vw;
    width: 89.33vw;
    padding: 6.4vw 6.4vw;

    @supports (width: 100svw) {
      bottom: 4svw;
      border-radius: 5.33svw;
      width: 89.33svw;
      padding: 6.4svw 6.4svw;
    }

    p:first-of-type {
      font-size: 4vw;
      margin-bottom: 2.66vw;
      line-height: 1.5;

      @supports (width: 100svw) {
        font-size: 4svw;
        margin-bottom: 2.66svw;
      }
    }

    p:last-of-type {
      font-size: 3.2vw;
      @supports (width: 100svw) {
        font-size: 3.2svw;
      }
      line-height: 1.5;
    }
  }
`;

const CookiePolicyClose = styled.div`
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;

  img {
    width: 20px;
    height: 20px;
  }
`;

const CookiePolicyClosePc = styled.img`
  display: block;
  ${mq('NARROW')} {
    display: none;
  }
`;

const CookiePolicyCloseSp = styled.img`
  display: none;
  ${mq('NARROW')} {
    display: block;
  }
`;

const images = [
  { guest: MvImgGuest01, panel: MvImgPanel01 },
  { guest: MvImgGuest02, panel: MvImgPanel02 },
  { guest: MvImgGuest03, panel: MvImgPanel03 },
  { guest: MvImgGuest04, panel: MvImgPanel04 },
  { guest: MvImgGuest05, panel: MvImgPanel05 },
];

const splashVisibilityRepository: ISplashVisibilityRepository = new SplashVisibilityRepository();
const cookieNotificationRepository: ICookieNotificationRepository =
  new CookieNotificationRepository();

export const Top: FC = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [showSplash, setShowSplash] = useState(splashVisibilityRepository.visible());
  const [showCookiePolicy, setShowCookiePolicy] = useState(cookieNotificationRepository.visible());
  const { projectList, openProjectCount } = useTopProjectList();
  const navigate = useNavigate();
  const scrollableElm = useRef<HTMLDivElement | null>(null);

  const splashClose = () => {
    setShowSplash(false);
    splashVisibilityRepository.setClose();
  };

  const cookiePolicyClose = () => {
    setShowCookiePolicy(false);
    cookieNotificationRepository.setClose();
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setActiveIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 4000);

    // スプラッシュを非表示
    setTimeout(() => {
      splashClose();
    }, 10000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <>
      {showSplash ? (
        <>
          <TopSplash />
          <CloseIcon onClick={splashClose}>
            <img src={IconCloseImg} />
          </CloseIcon>
        </>
      ) : (
        <>
          <LpHeader scrollableElm={scrollableElm} />
          <OverFlowY id="scrollableArea" ref={scrollableElm}>
            <TopBgContainer>
              <TopImgContainer>
                <img src={MvBaseImg} />
                {images.map((image, index) => (
                  <TopImgBox key={index} index={index} isActive={activeIndex === index}>
                    <img src={image.guest} />
                    <img src={image.panel} />
                  </TopImgBox>
                ))}
              </TopImgContainer>
              <TopTextContainer>
                <p>
                  セッションを通じて
                  <br />
                  新しいことに挑戦する企業とつながろう。
                </p>
                <RegisterButton onClick={() => navigate('/login/')}>
                  登録してSpreadyを始める
                </RegisterButton>
              </TopTextContainer>
              <ScrollIconContainer>
                <img src={ScrollImg} alt="scroll" />
                <ScrollIconLine />
              </ScrollIconContainer>
            </TopBgContainer>
            <TopProjectList projectList={projectList} showBalloon />
            <SessionTotal>
              <SessionTotalInner>
                <SessionTotalText>
                  現在のセッション総数
                  <br />
                  <span>毎日更新</span>
                </SessionTotalText>
                <SessionTotalCount>
                  <span>{openProjectCount}件</span>
                </SessionTotalCount>
              </SessionTotalInner>
            </SessionTotal>
            <TopCheckSession>
              ここでお見せできるのはほんの一部。
              <br />
              さっそく登録して、すべてのセッションをチェックしましょう。
            </TopCheckSession>
            <ServiceContainer>
              <TopSessionFlow />
            </ServiceContainer>
            <TopCheckSession>
              さあ、新しい出会いが
              <Break />
              あなたを待っています。
            </TopCheckSession>
            <TopEnjoyPoints />
            <TopProjectList projectList={projectList} />
            <TopClients />
            <TopQa />
            <StatementContainer>
              <StatementContent>
                <StatementTitle>
                  やりたいに
                  <br />
                  出会い続ける
                  <br />
                  世界をつくる
                </StatementTitle>
                <StatementText>
                  日本では転職や副業、業務委託など何らかの“仕事”の
                  <BreakPc />
                  選考を通じてしか、自分の働く環境以外の環境や機会に
                  <BreakPc />
                  触れ合う機会がありません。
                </StatementText>
                <StatementText>
                  副業が解禁され、パラレルキャリアという言葉も聞かれるように
                  <BreakPc />
                  なってきましたが、一部の業界以外ではまだまだ浸透してないのも
                  <BreakPc />
                  事実。副業をはじめる前に、何が自分の強みなのか、自分は何で
                  <BreakPc />
                  稼げるのか、まずはそこから考えたい。
                </StatementText>
                <StatementText>
                  副業やカジュアル面談より、もっともっとカジュアルに。
                  <br />
                  もっともっと世界を知りに行こう。
                  <br />
                  羽の生えた鳥のように、自分の好奇心や興味が赴くままに。
                  <br />
                  その活動を通じて、自分の強みが見つけられたらいい。
                  <br />
                  見つけられなくたっていい。
                  <br />
                  その活動がきっと未来の自分の糧になるのだから。
                </StatementText>
                <BreakPc />
                <OperatingCompany>
                  <OperatingCompanyLeft>
                    <p>運営会社</p>
                    <span>Spready株式会社</span>
                  </OperatingCompanyLeft>
                  <OperatingCompanyRight>
                    <LinkWithChild url="https://spready.co.jp/">コーポレートサイト</LinkWithChild>
                    <LinkWithChild url="https://note.com/spready/">
                      <img src={LogoNoteImg} />
                      <img src={IconBlankImg} />
                    </LinkWithChild>
                  </OperatingCompanyRight>
                </OperatingCompany>
              </StatementContent>
              <BgStatement>
                <img src={BgStatementImgSp} loading="lazy" />
              </BgStatement>
            </StatementContainer>
            <TopCheckSession>
              さあ、新しい出会いが
              <Break />
              あなたを待っています。
            </TopCheckSession>
            <FooterClientItem />
            {showCookiePolicy && (
              <CookiePolicy>
                <p>Cookieの利用について</p>
                <p>
                  このウェブサイトはクッキーを使用しています。このサイトを使用することにより、
                  <CustomLink
                    url="/info/policy/"
                    target="_blank"
                    text="プライバシーポリシー"
                    textDecoration="underline"
                  />
                  に同意したことになります。
                </p>
                <CookiePolicyClose onClick={cookiePolicyClose}>
                  <CookiePolicyClosePc src={IconCloseWhImg} />
                  <CookiePolicyCloseSp src={IconCloseWhImg} />
                </CookiePolicyClose>
              </CookiePolicy>
            )}
            <LpFooter scrollableElm={scrollableElm} />
          </OverFlowY>
        </>
      )}
    </>
  );
};
