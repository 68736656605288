import { useCallback, useContext, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { CompanyApplicationCore } from 'domain/entities/factories/CompanyApplication';
import { diContainerContext } from 'application/contexts/useDiContainer';
import { HttpBadRequest } from 'domain/types/httpResponse';
import { CompanyApplicationQuery } from 'interfaceAdapter/queries/CompanyApplication';

export const useFetch = () => {
  const diContainer = useContext(diContainerContext);
  const companyApplicationQuery = diContainer.resolve(CompanyApplicationQuery);
  const [param] = useSearchParams();
  const [urlId, setUrlId] = useState('');
  const defaultFormContents = {
    url_id: '',
    company_name: '',
    company_zipcode: '',
    company_address: '',
    charge_last_name: '',
    charge_first_name: '',
    charge_email: '',
    logo_use_enable: true,
    description: '',
  };
  const [applicationFormContent, setApplicationFormContent] =
    useState<CompanyApplicationCore>(defaultFormContents);
  const fetch = useCallback(async () => {
    const result = await companyApplicationQuery.fetch(urlId);

    if (result instanceof HttpBadRequest) {
      throw new Error(result?.message);
    }

    return result;
  }, [urlId]);

  useEffect(() => {
    if (!urlId) {
      return;
    }

    fetch().then((result) => {
      setApplicationFormContent(result);
    });
  }, [urlId]);

  useEffect(() => {
    const code = param.get('code') ?? '';
    setUrlId(code);
  }, []);

  return {
    applicationFormContent,
  };
};
