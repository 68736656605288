import { useCallback, useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import yup from 'utils/yup';
import { companyUserContext } from 'application/contexts/useCompanyUser';
import { diContainerContext } from 'application/contexts/useDiContainer';
import { CompanyUserRepository } from 'interfaceAdapter/repositories/CompanyUser';
import { CompanyUserCheckToken } from 'application/usecases/CompanyUserCheckToken';

const schema = yup
  .object({
    email: yup.string().required().email(),
    password: yup.string().required(),
  })
  .required();

type LoginForm = yup.InferType<typeof schema>;

export const useLoginForm = () => {
  const navigate = useNavigate();
  const diContainer = useContext(diContainerContext);
  const companyUserRepository = diContainer.resolve(CompanyUserRepository);
  const companyUserCheckToken = diContainer.resolve(CompanyUserCheckToken);
  const { setUser, setTicketCount, urlAfterLogin } = useContext(companyUserContext);
  const [loading, setLoading] = useState(false);
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<LoginForm>({
    resolver: yupResolver(schema),
    mode: 'onChange',
    defaultValues: {
      email: '',
      password: '',
    },
  });

  const redirectIfLoggedIn = useCallback(async () => {
    const result = await companyUserCheckToken.execute(false);
    if (result !== false) {
      setUser(result);
      setTicketCount(result.company.tickets ?? 0);
      navigate(urlAfterLogin);
    }
  }, [urlAfterLogin]);

  const login = handleSubmit(async ({ email, password }) => {
    setLoading(true);
    try {
      const user = await companyUserRepository.loginByEmail(email, password);
      setUser(user);
      navigate(urlAfterLogin);
    } finally {
      setLoading(false);
    }
  });

  const register = () => {
    navigate('/company/register/email/');
  };

  return {
    control,
    errors,
    loading,
    redirectIfLoggedIn,
    login,
    register,
  };
};
