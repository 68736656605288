export interface ICompanyApplication {
  url_id: string;
  company_name: string;
  company_zipcode: string;
  company_address: string;
  charge_last_name: string;
  charge_first_name: string;
  charge_email: string;
  logo_use_enable: boolean;
  description: string | null;
}

export class CompanyApplication {
  constructor(
    public url_id: string,
    public company_name: string,
    public company_zipcode: string,
    public company_address: string,
    public charge_last_name: string,
    public charge_first_name: string,
    public charge_email: string,
    public logo_use_enable: boolean,
    public description: string | null,
  ) {}
}
