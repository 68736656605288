import { useContext, useEffect, useState } from 'react';
import { companyCastedUsersFilterContext } from 'application/contexts/useCompanyCastedUsersFilter';

export const useFilterByWord = () => {
  const { filterCondition, reflectCurrentWord, reflectCurrentPage, setHasEmittedFetchCastings } =
    useContext(companyCastedUsersFilterContext);

  const [inputWord, setInputWord] = useState<string>(filterCondition.word);
  const [wordError, setWordError] = useState('');
  const [timerId, setTimerId] = useState<number>();

  useEffect(() => {
    if (timerId) {
      clearTimeout(timerId);
    }
    setWordError(''); // エラーメッセージをリセット

    if (inputWord && inputWord.length > 0 && inputWord.length < 2) {
      setWordError('2文字以上で入力してください');
      return;
    }

    // ワード絞り込み時にpagerを1ページ目に戻す
    if (filterCondition.page !== 1) {
      reflectCurrentPage(1);
    }

    const timeout = inputWord.length === 0 ? 0 : 700;

    const newTimerId = window.setTimeout(() => {
      setHasEmittedFetchCastings(true);
    }, timeout);
    setTimerId(newTimerId);

    return () => clearTimeout(newTimerId);
  }, [inputWord]);

  // 絞り込みワードの入力値を監視して実行
  useEffect(() => {
    reflectCurrentWord(inputWord);
  }, [inputWord, filterCondition.page]);

  return {
    inputWord,
    setInputWord,
    wordError,
  };
};
