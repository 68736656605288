import { useContext, useState } from 'react';
import { IProject } from 'domain/entities/Project/Project';
import { ProjectWithApplied } from 'domain/valueObjects/application/ProjectWithApplied';
import { userAppliedProjectIdsContext } from 'application/contexts/useUserAppliedProjectIds';
import { ProjectQuery } from 'interfaceAdapter/queries/Project';
import { diContainerContext } from 'application/contexts/useDiContainer';
import { ApplicationFactory } from 'domain/valueObjects/factories/Application';

export const useRecommendProjectAllItems = () => {
  const [projects, setProjects] = useState<ProjectWithApplied[] | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [totalPageCount, setTotalPageCount] = useState<number>(0);
  const { appliedProjectIds, fetchAppliedProjects } = useContext(userAppliedProjectIdsContext);
  const diContainer = useContext(diContainerContext);
  const projectQuery = diContainer.resolve(ProjectQuery);

  const fetchProjects = async ({ offset }: { offset: number }) => {
    setLoading(true);
    const fetchedProjects = await projectQuery.fetchBulk({
      offset: offset,
      word: null,
      filterType: 'featured',
    });

    const projectWithApplied = fetchedProjects.projects.map(
      (project: IProject): ProjectWithApplied =>
        ApplicationFactory.buildProjectWithApplied({ project, appliedProjectIds }),
    );

    setProjects(projectWithApplied);
    setTotalPageCount(fetchedProjects.total_page_count);
    setLoading(false);
  };

  return {
    loading,
    projects,
    totalPageCount,
    appliedProjectIds,
    fetchAppliedProjects,
    fetchProjects,
  };
};
