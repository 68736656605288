import yup from 'utils/yup';
import { CASTING_STATUS, CASTING_STATUS_DICT, Casting } from 'domain/entities/Casting/Casting';
import {
  BuildProjectInput,
  ProjectFactory,
  projectSchema,
} from 'domain/entities/factories/Project';
import { companySchema } from 'domain/entities/factories/Company';

export const managementUserSchema = yup.object({
  id: yup.number().required(),
  last_name: yup.string().required(),
  first_name: yup.string().required(),
  icon_image: yup.string().nullable().required(),
  status: yup.number().required(),
  job: yup.string().required(),
  department: yup.string().required(),
});

const castingUserSchema = {
  id: yup.number().required(),
  spreader_name: yup.string().nullable(),
  last_name: yup.string().required(),
  first_name: yup.string().required(),
  company_job: yup.string().nullable().optional(),
  company_name: yup.string().nullable().optional(),
  icon_image: yup.string().nullable(),
  urls: yup.array().of(yup.string().required()).optional(),
  age: yup.number().optional(),
  profiee_url: yup.string().nullable(),
  facebook_url: yup.string().nullable(),
};

export const answerCompanyUserSchema = yup.object({
  id: yup.number().required(),
  last_name: yup.string().required(),
  first_name: yup.string().required(),
  icon_image: yup.string().nullable(),
  status: yup.number().required(),
  company: yup
    .object({
      id: yup.number().required(),
      name: yup.string().required(),
      icon_image: yup.string().nullable(),
      logo_display_pushed: yup.boolean().required(),
      search_words: yup.string().nullable(),
      status: yup.number().required(),
    })
    .required(),
  job: yup.string().required(),
  department: yup.string().required(),
});
export type AnswerCompanyUserSchema = Readonly<yup.InferType<typeof answerCompanyUserSchema>>;

export const castingSchemaBase = {
  id: yup.number().required(),
  spreader: yup.object().shape(castingUserSchema).default(null).nullable().optional(),
  introduced_user: yup.object().shape(castingUserSchema).default(null).nullable().optional(),
  message_from_spreader: yup.string().nullable().default('').optional(),
  status: yup.number().optional(),
  note: yup.string().nullable(),
  meetup_date_at: yup.date().nullable(),
  assigned_at: yup.date().nullable().optional(),
  chat_room_id: yup.string().nullable(),
  survey_casted_answer_at: yup.date().nullable(),
  survey_company_user_answer_at: yup.date().nullable(),
  passed_days: yup.number().nullable(),
  match_points: yup
    .array(
      yup.object({
        question: yup.string().required(),
        answer: yup.string().required(),
      }),
    )
    .required(),
  description: yup.string().nullable().optional(),
  exists_on_crm_list: yup.boolean(),
  is_chat_archived: yup.boolean().nullable(),
  meetup_finished: yup.boolean().optional(),
  decline_message: yup.string().nullable().optional(),
  created_at: yup.string().required(),
};

export const castingSchema = yup.object({
  ...castingSchemaBase,
  project: yup
    .object({
      id: yup.number().required(),
      title: yup.string().required(),
      management_users: yup.array().of(managementUserSchema).optional(),
      chat_template_text: yup.string().nullable(),
      main_pic: yup.string().nullable(),
      privacy: yup.boolean().optional(),
    })
    .required(),
  feedback_to_spreader_by_company: yup
    .object({
      answer_company_user: answerCompanyUserSchema.default(null).nullable().optional(),
      answer_text: yup.string().nullable().optional(),
    })
    .nullable()
    .optional(),
});

export type CastingSchema = Readonly<yup.InferType<typeof castingSchema>>;

export const castingWithProjectSchema = yup.object({
  ...castingSchemaBase,
  project: projectSchema.required(),
  feedback_to_spreader_by_company: yup
    .object()
    .shape({
      answer_company_user: answerCompanyUserSchema.required(),
      answer_text: yup.string().nullable().required(),
    })
    .nullable()
    .optional(),
});
export type CastingWithProjectSchema = Readonly<yup.InferType<typeof castingWithProjectSchema>>;

export const writeIntroductionSchema = yup.object({
  ...castingSchemaBase,
  project: yup
    .object({
      id: yup.number().required(),
      title: yup.string().required(),
      chat_template_text: yup.string().nullable(),
      main_pic: yup.string().nullable(),
      created_at: yup.date().required(),
      start_at: yup.date().nullable(),
      company: companySchema,
    })
    .required(),
});
export type WriteIntroductionSchema = Readonly<yup.InferType<typeof writeIntroductionSchema>>;

export interface ICastingFactory {
  buildEmptyCasting(): Casting;
  buildCasting(data: CastingSchema | CastingWithProjectSchema, type?: string): Casting;
}
export class CastingFactory implements ICastingFactory {
  public buildEmptyCasting(): Casting {
    return new Casting(
      0, // id
      null, // project
      null, // spreader
      null, // introduced_user
      '', // message_from_spreader
      null, // status
      '', // note
      null, // meetup_date_at
      null, // assigned_at
      '', // chat_room_id
      null, // survey_casted_answer_at
      null, // survey_company_user_answer_at
      null, // feedback
      null, // passed_days
      [], // match_points
      '', // description
      null, // exists_on_crm_list
      false, // is_chat_archived
      new Date(), // created_at4
      false, // meetup_finished
      null, // decline_message
    );
  }

  public buildCasting(data: CastingSchema | CastingWithProjectSchema, type?: string): Casting {
    const spreader = data.spreader || null;
    const introduced_user = data.introduced_user || null;
    const meetup_date_at = data.meetup_date_at ? new Date(data.meetup_date_at) : null;
    const assigned_at = data.assigned_at ? new Date(data.assigned_at) : null;
    const survey_casted_answer_at = data.survey_casted_answer_at
      ? new Date(data.survey_casted_answer_at)
      : null;
    const survey_company_user_answer_at = data.survey_company_user_answer_at
      ? new Date(data.survey_company_user_answer_at)
      : null;
    const project = data.project
      ? new ProjectFactory().buildProject(data.project as BuildProjectInput)
      : null;
    const feedback = {
      answer_company_user: data.feedback_to_spreader_by_company?.answer_company_user || null,
      answer_text: data.feedback_to_spreader_by_company?.answer_text || null,
    };
    const meetupFinished = meetup_date_at ? meetup_date_at <= new Date() : false;
    const status =
      type === 'self_applied'
        ? CASTING_STATUS.ISSUE_INVITE_URL
        : data.status === 0 || data.status
        ? (CASTING_STATUS_DICT[data.status] as CASTING_STATUS)
        : null;

    const matchPoints = data.match_points.map((matchPoint) => {
      return {
        question: matchPoint.question.replace(/\\n/g, '\n'),
        answer: matchPoint.answer.replace(/\\n/g, '\n'),
      };
    });

    return new Casting(
      data.id,
      project,
      spreader,
      introduced_user,
      data.message_from_spreader ?? '',
      status,
      data.note ?? '',
      meetup_date_at,
      assigned_at,
      data.chat_room_id === undefined ? null : data.chat_room_id, // 紹介文記入中の Castings(= GET /api/user/waiting_introduction_self_cast_urls のレスポンス) には chat_room_id が含まれないため、undefined なのを null に寄せる
      survey_casted_answer_at,
      survey_company_user_answer_at,
      feedback,
      data.passed_days ?? null,
      matchPoints,
      data.description ? data.description.replace(/\\n/g, '\n') : '',
      data.exists_on_crm_list ?? null,
      data.is_chat_archived ?? null,
      new Date(data.created_at),
      meetupFinished,
      data.decline_message,
    );
  }
}
