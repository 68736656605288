import {
  MEET_REQUEST_STATUS,
  MeetRequest,
  IMeetRequestUser,
  IMeetRequestCompanyUser,
  IMeetRequestProject,
} from 'domain/entities/MeetRequest/MeetRequest';

import yup from 'utils/yup';

export interface IMeetRequestFactory {
  buildEmptyMeetRequest(): MeetRequest;
  buildMeetRequest(data: MeetRequest): MeetRequest;
}

export const meetRequestCompanyUserSchema = yup.object().shape({
  id: yup.number().required(),
  last_name: yup.string().required(),
  first_name: yup.string().required(),
  icon_image: yup.string().nullable(),
  status: yup.number().required(),
  company: yup
    .object({
      id: yup.number().nullable().defined(),
      name: yup.string().nullable().defined(),
      icon_image: yup.string().nullable(),
      logo_display_pushed: yup.boolean().nullable().defined(),
      search_words: yup.string().nullable(),
      status: yup.number().required(),
    })
    .required(),
});

export const meetRequestUserSchema = yup.object().shape({
  id: yup.number().required(),
  last_name: yup.string().required(),
  first_name: yup.string().required(),
  icon_image: yup.string().nullable(),
  spreader_name: yup.string().nullable(),
});

const meetRequestProjectSchema = yup.object().shape({
  id: yup.number().required(),
  title: yup.string().required(),
  main_pic: yup.string().required(),
  privacy: yup.boolean().optional(),
});

export const meetRequestSchema = yup.object().shape({
  id: yup.number().required(),
  from_company_user: meetRequestCompanyUserSchema.required(),
  to_spreader: meetRequestUserSchema.required(),
  project: meetRequestProjectSchema,
  created_at: yup.date().required(),
  status: yup.string().oneOf(Object.values(MEET_REQUEST_STATUS)).required(),
  chat_room_id: yup.string().default(null).nullable(),
  topic: yup.string().required(),
  attender: yup.string().required(),
  exists_on_crm_list: yup.boolean().required(),
  meeting_at: yup.date().nullable(),
  survey_url: yup.string().nullable(),
});

export class MeetRequestFactory implements IMeetRequestFactory {
  public buildEmptyMeetRequest(): MeetRequest {
    const emptyMeetRequestCompanyUser: IMeetRequestCompanyUser = {
      id: 0,
      last_name: '',
      first_name: '',
      icon_image: '',
      status: 0,
      company: {
        id: 0,
        name: '',
        icon_image: '',
        logo_display_pushed: false,
        search_words: '',
        status: 0,
      },
    };

    const emptyMeetRequestUser: IMeetRequestUser = {
      id: 0,
      last_name: '',
      first_name: '',
      icon_image: '',
      spreader_name: '',
    };

    const emptyMeetRequestProject: IMeetRequestProject = {
      id: 0,
      title: '',
      main_pic: '',
      privacy: false,
    };

    return new MeetRequest(
      0,
      emptyMeetRequestCompanyUser,
      emptyMeetRequestUser,
      emptyMeetRequestProject,
      new Date(),
      '',
      '',
      '',
      '',
      false,
      null,
      null,
    );
  }

  public buildMeetRequest(data: MeetRequest): MeetRequest {
    const meetingAt = data.meeting_at ? new Date(data.meeting_at) : null;

    return new MeetRequest(
      data.id,
      data.from_company_user,
      data.to_spreader,
      data.project,
      new Date(data.created_at),
      data.status,
      data.chat_room_id,
      data.topic,
      data.attender,
      data.exists_on_crm_list,
      meetingAt,
      data.survey_url,
    );
  }
}
