import { inject, injectable } from 'tsyringe';
import { buildResponse, HttpBadRequest, HttpOK } from 'domain/types/httpResponse';
import type { ICompanyApplyRepository } from 'application/repositorySchemas/ICompanyApplyRepository';
import type { ICompanyApplication } from 'domain/entities/CompanyApplication/CompanyApplication';
import type { ICompanyApplyClient } from 'interfaceAdapter/gatewaySchemas/ICompanyApplyClient';

@injectable()
export class CompanyApplyRepository implements ICompanyApplyRepository {
  constructor(
    @inject('ICompanyApplyClient')
    public readonly companyApplyClient: ICompanyApplyClient,
  ) {}

  public async apply(
    companyApplication: Omit<ICompanyApplication, 'description'>,
  ): Promise<HttpOK | HttpBadRequest> {
    const { message, status } = await this.companyApplyClient.post({ companyApplication });

    return buildResponse(status, message);
  }
}
