import { ICompanyMoneyForwardDepartmentRepository } from 'application/repositorySchemas/ICompanyMoneyForwardDepartmentRepository';
import { type ICompanyUserTokenRepository } from 'application/repositorySchemas/ICompanyUserTokenRepository';
import {
  IPostInput,
  type ICompanyMoneyForwardDepartmentsClient,
} from 'interfaceAdapter/gatewaySchemas/ICompanyMoneyForwardDepartmentsClient';
import { inject, injectable } from 'tsyringe';

@injectable()
export class CompanyMoneyForwardDepartmentRepository
  implements ICompanyMoneyForwardDepartmentRepository
{
  constructor(
    @inject('ICompanyUserTokenRepository')
    public readonly companyUserToken: ICompanyUserTokenRepository,
    @inject('ICompanyMoneyForwardDepartmentsClient')
    public readonly client: ICompanyMoneyForwardDepartmentsClient,
  ) {}

  public async create(input: IPostInput['body']) {
    const { data, message } = await this.client.post({
      token: this.companyUserToken.get() || '',
      body: input,
    });

    if (message) {
      throw new Error(message);
    }

    return data;
  }

  public async delete(id: string) {
    const { message } = await this.client.delete({
      token: this.companyUserToken.get() || '',
      id,
    });

    if (message) {
      throw new Error(message);
    }
  }
}
