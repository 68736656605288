import { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  CompanyApplicationCore,
  companyApplicationCoreSchema,
} from 'domain/entities/factories/CompanyApplication';
import yup from 'utils/yup';
import { diContainerContext } from 'application/contexts/useDiContainer';
import { CompanyApplyRepository } from 'interfaceAdapter/repositories/CompanyApply';
import { HttpBadRequest } from 'domain/types/httpResponse';

// Formのバリデーション用
const formSchema = yup
  .object({
    ...companyApplicationCoreSchema,
    accept_terms: yup.boolean().default(false).isTrue('必須項目です'),
    accept_policy: yup.boolean().default(false).isTrue('必須項目です'),
  })
  .required();
type ApplicationForm = yup.InferType<typeof formSchema>;

export const useApplicationForm = ({
  applicationFormContent,
}: {
  applicationFormContent: CompanyApplicationCore;
}) => {
  const diContainer = useContext(diContainerContext);
  const companyApplyRepository = diContainer.resolve(CompanyApplyRepository);
  const [isCompleted, setIsCompleted] = useState(false);
  const {
    control,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm<ApplicationForm>({
    resolver: yupResolver(formSchema),
    mode: 'onChange',
    defaultValues: formSchema.cast({}),
  });
  const [requesting, setRequesting] = useState(false);

  // フォームに初期値をセット
  useEffect(() => {
    const {
      url_id,
      company_name,
      company_zipcode,
      company_address,
      charge_last_name,
      charge_first_name,
      charge_email,
      logo_use_enable,
    } = applicationFormContent;

    setValue('url_id', url_id);
    setValue('company_name', company_name);
    setValue('company_zipcode', company_zipcode);
    setValue('company_address', company_address);
    setValue('charge_last_name', charge_last_name);
    setValue('charge_first_name', charge_first_name);
    setValue('charge_email', charge_email);
    setValue('logo_use_enable', logo_use_enable);
  }, [applicationFormContent]);

  const apply = async (
    data: Omit<ApplicationForm, 'accept_terms' | 'accept_policy' | 'description'>,
  ) => {
    const response = await companyApplyRepository.apply(data);
    if (response instanceof HttpBadRequest) {
      throw new Error(response?.message);
    }
  };

  const onSubmit = handleSubmit(async (data) => {
    try {
      setRequesting(true);
      const {
        url_id,
        company_name,
        company_zipcode,
        company_address,
        charge_last_name,
        charge_first_name,
        charge_email,
        logo_use_enable,
      } = data;
      await apply({
        url_id,
        company_name,
        company_zipcode,
        company_address,
        charge_last_name,
        charge_first_name,
        charge_email,
        logo_use_enable,
      });
      setIsCompleted(true);
    } finally {
      // エラー通知に気づかせる or 送信後トップに移動してほしいため
      window.scrollTo({ top: 0, behavior: 'smooth' });
      setRequesting(false);
    }
  });

  return {
    control,
    errors,
    requesting,
    onSubmit,
    isCompleted,
  };
};
